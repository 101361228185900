<template>
  <div class="dashboard">
    <dash-side-bar />
    <div class="dashboard__content">
      <BasicTopNotification />
      <profile-header />
      <profile-content>
      </profile-content>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashSideBar from '../DashSidebar';
import ProfileHeader from './ProfileHeader';
import ProfileContent from './ProfileContent';
import BasicTopNotification from '../Basic/BasicTopNotification';
import LocalStorageService from '../../services/LocalStorageService';

export default {
  data() {
    return {
    };
  },
  components: {
    DashSideBar,
    ProfileHeader,
    ProfileContent,
    BasicTopNotification
  },

  computed: {},
  methods: {
     ...mapActions(['getAdvisorByUserName']),
  },
   created() {
    const user = LocalStorageService.getLocalStorageItem().UserName;
    if (user != undefined || user != null) {
      this.getAdvisorByUserName(user);
    }
  }
};
</script>

<style scoped></style>

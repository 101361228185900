import mainAPI from '../mainAPI';

const activeChats = {
  getUpdates: params => {
    return mainAPI.post(`/api/chat/proxy/GetUpdates`, params);
  }
};

const sendMessage = {
  addMessage: params => {
    return mainAPI.post(`/api/chat/proxy/SendMessage`, params);
  }
};

const uploadFile = {
  addFile: params => {
    return mainAPI.post(`/api/chat/proxy/UploadFile`, params.formData, params.config);
  }

};

const cancelUpload = {
  cancelFile: params => {
    return mainAPI.post(`/api/chat/proxy/CancelUpload`, params);
  }
}

const transfers = {
  requestTransfer: params => {
    return mainAPI.post(`/api/chat/proxy/TransferChannel`, params);
  },
  acceptTransfer: params => {
    return mainAPI.post(`/api/chat/proxy/AcceptChannel`, params);
  },
  rejectTransfer: params => {
    return mainAPI.post(`/api/chat/proxy/RejectChannel`, params);
  },
  exitChannel: params => {
    return mainAPI.post(`/api/chat/proxy/exitchannel`, params);
  },
  endChannel: params => {
    return mainAPI.post(`/api/chat/proxy/endChannel`, params);
  }
}

export default {
  activeChats,
  sendMessage,
  uploadFile,
  cancelUpload,
  transfers
};

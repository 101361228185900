var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-wrapper",class:_vm.isUserAdvisor(_vm.messageProp.UserId) ? 'message-wrapper--right' : ''},[(!_vm.isUserAdvisor(_vm.messageProp.UserId))?_c('avatar',{attrs:{"fullname":_vm.messageProp.UserDisplayName ? _vm.decodeString(_vm.messageProp.UserDisplayName) : 'A',"size":44,"color":"#105c46"}}):_vm._e(),_c('div',{staticClass:"message-wrapper__container"},[_c('div',{directives:[{name:"linkified",rawName:"v-linkified"}],staticClass:"message-wrapper__message",class:_vm.isUserAdvisor(_vm.messageProp.UserId)
          ? 'message-wrapper__message--advisor'
          : ''},[_vm._v(" "+_vm._s(_vm.decodeString(_vm.messageProp.Message))+" ")]),(_vm.isUserAdvisor(_vm.messageProp.UserId))?_c('div',{staticClass:"message-wrapper__send-state"},[_c('SendReceiveElement',{attrs:{"sentState":_vm.messageProp.Read ? 'Read' : _vm.messageProp.Delivered ? 'Delivered' : '',"time":_vm.messageProp.Updated}})],1):_vm._e()]),(
      _vm.isUserAdvisor(_vm.messageProp.UserId) && 
      _vm.getAdvisorPicture(_vm.messageProp.UserId) !== ''
    )?_c('avatar',{attrs:{"image":_vm.getAdvisorPicture(_vm.messageProp.UserId),"size":44}}):_vm._e(),(
      _vm.isUserAdvisor(_vm.messageProp.UserId) &&
       _vm.getAdvisorPicture(_vm.messageProp.UserId) === ''
    )?_c('avatar',{attrs:{"fullname":_vm.getAdvisorDisplayName(_vm.messageProp.UserId)
        ? _vm.advisorByName.Advisor.UserDisplayName
        : 'A',"size":44,"color":"#105c46"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
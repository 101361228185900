<template>
  <div
    class="chats-content__progress-container"
    v-if="isValid && isVisible && !isSent"
  >
    <span class="chats-content__filename">
      <slot v-if="!isLoaded && !sendError" name="uploading" />
      <slot v-else-if="!sendError && isLoaded" name="uploaded" />
      <slot v-else-if="sendError" name="error" />
    </span>

    <div class="chats-content__progress-wrapper">
      <progress
        ref="progressBar"
        max="100"
        class="chats-content__progress"
        :value="uploadPercentage"
      ></progress>
      <RemoveIcon
        v-if="isLoaded"
        class="chats-content__icon"
        @click="cancelUpload()"
      />
    </div>
    <div>
      <p v-if="sendError" class="chats-content__error">
        {{errorMessage}}
      </p>
    </div>
  </div>
</template>

<script>
import RemoveIcon from '../../assets/icons/Remove.svg';
import Vue from 'vue';

export default {
  name: 'progress-bar',
  data() {
    return {
      isValid: true,
    };
  },
  props: [
    'uploadPercentage',
    'errors',
    'transactionId',
    'currentChat',
    'isLoaded',
    'file',
    'currentInstance',
    'removeFile',
    'isVisible',
    'isSent',
    'errorMessage',
    'sendError'
  ],
  components: {
    RemoveIcon,
  },
  methods: {
    cancelUpload() {
      const activeAdvisor = this.getActiveAdvisor();
      this.removeFile({
        ChannelId: activeAdvisor.ChannelId,
        UserId: activeAdvisor.UserId,
        TransactionGroupId: this.transactionId,
      });
      this.isValid = false;
    },
    getActiveAdvisor() {
      return this.currentChat.ChannelUsers.filter(
        (item) =>
          item.UserRoleName === 'Advisor' && item.UserStatus === 'Active'
      )[0];
    },
  },
};
</script>

<style scoped>
.chats-content {
  &__progress {
    @apply w-full bg-green-progress-light h-3 rounded-progress mr-6;

    &::-webkit-progress-bar {
      @apply bg-green-progress-light rounded-progress;
    }

    &::-webkit-progress-value {
      @apply bg-green-progress;
    }

    &::-moz-progress-bar {
      @apply bg-green-progress rounded-progress;
    }

    &::-ms-fill {
      @apply bg-green-progress rounded-progress;
    }

    &-container {
      @apply mx-12 mt-auto;
    }

    &-wrapper {
      @apply flex items-center;
    }
  }
  &__icon {
    @apply w-10;
  }
  &__filename {
    @apply text-grey-advisor text-small-chat cursor-pointer;
  }

  &__error {
    @apply text-red-500 text-small-chat;
  }
}
</style>

import Vue from 'vue';
import api from '../../api/archive-api/api';
import { actionTypes } from '../actionTypes';

const state = {
  initialSearchState: false,
  searchKeyword: '',
  archivedChats: [],
  archivedMessages: [],
  currentArchivedChat: [],
};

const getters = {
  initialSearchState: state => state.initialSearchState,
  searchKeyword: state => state.searchKeyword,
  archivedChats: state => state.archivedChats,
  archivedChatsCount: state => state.archivedChats.length,
  archivedMessages: state => state.archivedMessages,
  currentArchivedChat: state => state.currentArchivedChat,
  archivedCurrentChatUserAdvisor: state => state.currentArchivedChat.ChannelUsers.filter(item => item.UserRoleName === 'Advisor')[0],
  archivedCurrentChatUserMember: state => state.currentArchivedChat.ChannelUsers.filter(item => item.UserRoleName === 'Member')[0]
}

const actions = {
  async getArchivedChats({ commit, dispatch }, queryString) {
    let response;

    //Reset currentArchiveChat
    commit(actionTypes.RESET_CURRENT_ACTIVE_ARCHIVE_CHAT);

    const query = {
      "Query": queryString
    };

    try {
      response = await api.channels.searchChannels(query);
      commit(actionTypes.SET_ARCHIVED_RESULTS, response.data);
      dispatch('triggerInitialState');
    } catch (error) {
      Vue.$log.error('Error - getArchivedChats:', error);
    }
  },

  async setActiveArchiveChat({ commit, getters }, chatInfo) {
    const filteredChat = getters.archivedMessages.filter(obj => {
      obj.Id === chatInfo.Id;
    })
    
    if (filteredChat.length > 0) {
      commit(actionTypes.SET_ACTIVE_ARCHIVE_CHAT, filteredChat);
    } else {
      let response;
      try {
        response = await api.channels.getChannel(chatInfo);
        commit(actionTypes.SET_ACTIVE_ARCHIVE_CHAT, response.data);

      } catch (error) {
        Vue.$log.error('Error - setActiveArchiveChat:', error);
      }
    }
  },

  async sendEmailTranscriptAdvisor({ commit, state, rootState }) {

    const query = {
      "ChannelId": state.currentArchivedChat.Id
    }
    let response;

    try {
      response = await api.channels.sendMeTranscript(query);
    } catch (error) {
      Vue.$log.error('Error - sendEmailTranscript:', error);
    }
  },
  async sendEmailTranscriptMember({ commit, state }) {

    const query = {
      "ChannelId": state.currentArchivedChat.Id
    }
    let response;

    try {
      response = await api.channels.sendTranscript(query);
    } catch (error) {
      Vue.$log.error('Error - sendEmailTranscript:', error);
    }
  },

  setSearchKeyword({ commit }, keyword) {
    commit(actionTypes.SET_SEARCH_KEYWORD, keyword);
  },

  triggerInitialState({ commit }) {
    commit(actionTypes.SET_INITIAL_SEARCH_STATE);
  },

  clearArchives({ commit }) {
    commit(actionTypes.CLEAR_ALL_ARCHIVES);
  }
}

const mutations = {
  SET_INITIAL_SEARCH_STATE(state) {
    state.initialSearchState = true;
  },
  SET_SEARCH_KEYWORD(state, searchKeyword) {
    state.searchKeyword = searchKeyword;
  },
  SET_ARCHIVED_RESULTS(state, archivedChats) {
    state.archivedChats = archivedChats;
  },

  SET_ACTIVE_ARCHIVE_CHAT(state, chat) {
    state.currentArchivedChat = chat;
  },
  RESET_CURRENT_ACTIVE_ARCHIVE_CHAT(state) {
    state.currentArchivedChat = [];
  },
  CLEAR_ALL_ARCHIVES(state) {
    state.initialSearchState = false;
    state.searchKeyword = '';
    state.archivedChats = [];
    state.archivedMessages = [];
    state.currentArchivedChat = [];
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};
<template>
  <div class="chats-header">
    <div class="chats-header__content">
      <div class="chats-header__section chats-header__section--strong">
        Active Chats ({{ activeChats }})
      </div>
      <div class="chats-header__section-secondary">
        <div class="chats-header__section chats-header__section-secondary--strong">
          {{ getActiveMemberDetails.UserDisplayName }}
        </div>
        <div class="chats-header__section">
          Member Number: {{ getActiveMemberDetails.MemberNumber }}
        </div>
        <div class="chats-header__section">
          {{ getActiveMemberDetails.MemberSpeciality }}
        </div>
        <div class="chats-header__section chats-header__section-secondary--strong">
          Ref: {{ getActiveMemberDetails.ChannelId }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getActiveChat } from '../mixins/getActivesChat';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  mixins: [getActiveChat],
  computed: {
    ...mapGetters(['chats', 'currentChat']),
    activeChats() {
      return this.chats.length > 0
        ? this.chats.filter((chat) => chat.Status === 'InProgress').length
        : 0;
    },
    getActiveMemberDetails() {
      return this.currentChat
        ? this.currentChat.ChannelUsers.filter(
            (user) => user.UserRoleName === 'Member'
          )[0]
        : {};
    },
  },
};
</script>

<style scoped>
.chats-header {
  @apply border-b-1 border-grey-divider;

  &__section {
    @apply pt-16 pb-8 font-kohinoor-demi text-mid-chat text-center duration-100;
    /* padding-left: 144px; */

    &-secondary {
      @apply flex justify-between flex-row w-full justify-around;

      &--strong {
        @apply font-kohinoor-med;

        &:first-child {
          padding-left: 235px;
        }
      }
    }
    &--strong {
      @apply font-kohinoor-med pl-17;
    }
  }

  &__content {
    @apply flex;
  }
}
</style>

import mainAPI from '../mainAPI';

export const getActivities = {
  getAll: params => {
    return mainAPI.post('/api/chat/proxy/GetActivities', params);
  },

  joinChat: params => {
    return mainAPI.post('/api/chat/proxy/joinchannel', params);
  }
};

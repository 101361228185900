<template>
  <div class="chats-active">
    <div class="no-results" v-if="chats.length === 0">
      There are no active chats currently available
    </div>
    <div
      class="chats-active__container"
      v-else
      v-for="(chat, index) in chats"
      :key="index"
    >
      <router-link :to="`/chats/${chat.Id}`" class="chats-active__message">
        <ChatActiveResultCard
          :messageProp="chat"
          :isActive="currentChat && chat.Id === currentChat.Id ? true : false"
          @click.native="
            setActiveChat({
              channelId: chat.Id,
              index: chat.ChatMessages.length - 1,
            })
          "
        />
        <ChatNotificationCounter
          v-if="currentChat && showNotification(chat)"
          :counterValue="chat.NotificationCount"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import ChatActiveResultCard from './ChatActiveResultCard';
import ChatNotificationCounter from './ChatNotificationCounter';
import { mapGetters, mapActions } from 'vuex';
import { getActiveChat } from '../mixins/getActivesChat';

export default {
  data() {
    return {
      items: [],
      lastMessages: [],
    };
  },
  components: {
    ChatActiveResultCard,
    ChatNotificationCounter,
  },
  mixins: [getActiveChat],
  computed: {
    ...mapGetters(['chats', 'currentChat', 'progressBarInstances']),
  },
  methods: {
    ...mapActions(['setActiveChat', 'processNotifications']),

    showNotification(chat) {
      this.processNotifications(this.chats);
      return chat.Id !== this.currentChat.Id &&
        chat.NotificationCount &&
        chat.NotificationCount > 0
        ? true
        : false;
    },



    showCorrectProgressBars(chatId) {
      for (let i = 0; i < this.progressBarInstances.length; i++) {
        if (this.progressBarInstances[i].$props.currentChat.Id == chatId) {
          if (this.progressBarInstances[i].$data.isValid) {
            this.progressBarInstances[i].$props.isVisible = true;
          }
        } else {
          this.progressBarInstances[i].$props.isVisible = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.chats-active {
  @apply bg-grey-active;
  min-width: 440px;

  &__container {
    @apply bg-grey-active flex flex-col items-center;
    width: 440px;
  }

  &__message {
    @apply flex justify-center w-full cursor-auto;
  }

  &__warning {
    @apply text-red-alert pt-6;
  }

  &__title {
    @apply font-kohinoor-med text-small-chat;
  }

  &__text {
    @apply text-mid-chat font-kohinoor-demi truncate;
    max-width: 230px;
  }

  .no-results {
    @apply flex justify-center opacity-50 items-center text-center h-full;
  }
}

.router-link-exact-active {
  .chats-active__container & {
    @apply bg-transparent;
  }
}

.avatar {
  @apply font-kohinoor-light ml-8 my-7 text-green-chat;
  font-size: 1.8rem !important ;
}
</style>

<template>
  <div class="profile-content">
    <modals-container name="ProfileEdit" />
    <div class="profile-content__avatar-container">
      <edit class="profile-content__icon" />
      <input
        ref="file"
        name="Select File"
        type="file"
        class="profile-content__input-file"
        @change="uploadProfileImage"
      />
      <avatar
        v-if="advisorByName.Advisor.PictureUrl != ''"
        :image="advisorByName.Advisor.PictureUrl"
        :size="268"
      ></avatar>
      <avatar
        v-if="
          advisorByName.Advisor.UserDisplayName &&
            advisorByName.Advisor.PictureUrl === ''
        "
        :fullname="
          advisorByName.Advisor.UserDisplayName
            ? advisorByName.Advisor.UserDisplayName
            : 'A'
        "
        :size="268"
        color="#105c46"
      ></avatar>
    </div>
    <div class="profile-content__text-container">
      <edit
        class="profile-content__icon profile-content__icon--right"
        @click.prevent="showEdit()"
      />
      <h2 class="profile-content__title">
        About me
      </h2>
      <p class="profile-content__text">
        {{ advisorByName.Advisor.Description }}
      </p>
      <div class="profile-content__subtext">
        <div class="profile-content__subtitle">Specialist Advice Areas</div>
        <div class="profile-content__paragraph">
          {{ advisorByName.Advisor.SpecialistAdviceAreas }}
        </div>
      </div>
      <div class="profile-content__subtext">
        <div class="profile-content__subtitle">email</div>
        <div class="profile-content__paragraph">
          {{ advisorByName.Advisor.Email }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from 'vue-avatar-component';
import Edit from '../../assets/icons/Edit.svg';
import ProfileEdit from '../profile/ProfileEdit';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      url: require('../../assets/images/1_Justin_GettyImages-985138634@3x.jpg'),
    };
  },
  components: {
    Avatar,
    Edit,
  },
  computed: {
    ...mapGetters(['advisorByName']),
  },
  methods: {
    ...mapActions(['submitProfileImage', 'getAdvisorByUserName']),
    showEdit() {
      this.$modal.show(
        ProfileEdit,
        {},
        {
          draggable: true,
          height: 'auto',
          width: '807px',
        }
      );
    },
    uploadProfileImage() {
      this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append('File', this.file);
      formData.append('UserName', this.advisorByName.Advisor.UserId);

      this.submitProfileImage(formData);
    },
  },
};
</script>

<style scoped>
.profile-content {
  @apply bg-cover flex;
  margin-left: 7.6rem;
  padding-top: 7rem;

  &__avatar-container {
    @apply relative w-full;
    max-width: 268px;
  }

  &__input {
    @apply w-full h-full resize-none;
    min-height: 100px;
    &:focus {
      @apply outline-none;
    }

    &-file {
      @apply absolute left-0 top-0 opacity-0 cursor-pointer;
    }
  }

  &__icon {
    @apply absolute cursor-pointer;
    right: 1.5rem;

    &--right {
      @apply right-0;
    }
  }

  &__title {
    @apply text-4xl;
  }

  &__subtitle {
    @apply font-kohinoor-med text-small-chat text-grey-advisor capitalize;
  }

  &__text {
    @apply pt-8 whitespace-pre-line;

    &-container {
      @apply w-full relative break-words text-2xl;
      max-width: 664px;
      margin-left: 9.7rem;
    }
  }

  &__paragraph {
    @apply pb-13 pt-2 whitespace-pre-line;
  }

  &__subtext {
    @apply border-b-2 border-solid border-grey-divider pt-17;
  }
}
</style>

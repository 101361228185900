import Vue from 'vue';
import App from './App.vue';
import VueLogger from 'vuejs-logger';
import router from './router';
import { store } from './store/store';
import '@/assets/css/vendors/tailwind.css';
import '@/assets/css/global.css';
import VModal from 'vue-js-modal';
import linkify from 'vue-linkify';

Vue.config.productionTip = false;
const isProduction = process.env.NODE_ENV === 'production';
Vue.directive('linkified', linkify);

const loggerOptions = {
  isEnabled: true,
  logLevel : isProduction ? 'error' : 'debug',
  showLogLevel : true,
  separator: '|',
  showConsoleColors: true
}

const vModelOptions = {
  dynamic: true
}

Vue.use(VueLogger, loggerOptions);
Vue.use(VModal, vModelOptions);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

<template>
  <div class="archive-chat-content">
    <div class="archive-chat-content__container" ref="container">
      <div class="no-chat-selected" v-if="currentArchivedChat.length === 0">
        No Chat Selected
      </div>
      <div v-else class="archive-chat-content__message-container">
        
        <div class="archive-chat-content__message" v-for="(message, index) in currentArchivedChat.ChatMessages" :key="index">
          <BasicChatElement v-if="message.Command === 'Message'" :messageProp="message" :advisor="getAdvisor" :member="getMember" :key="message" />
          <BasicContextElement v-if="isContextualRequest(message.Command)" :messageProp="message" :chatProp="currentArchivedChat" :key="message" />
          <BasicMediaElement v-if="message.Command === 'Document' || message.Command === 'Media'" :messageProp="message" :advisor="getAdvisor" :member="getMember" :key="message"/>
        </div>

      </div>
    </div>
    <div class="archive-chat-content__bottom-sticky" v-if="currentArchivedChat.length !== 0">
      <button class="archive-chat-content__button" @click.prevent="emailTranscriptMember">
          Email Transcript to Member
      </button>
      <button class="archive-chat-content__button" @click.prevent="emailTranscriptAdvisor">
          Email Transcript to Adviser
      </button>
    </div>
  </div>
</template>

<script>
import BasicChatElement from '../Basic/BasicChatElement';
import BasicContextElement from '../Basic/BasicContextElement';
import BasicMediaElement from '../Basic/BasicMediaElement';
import { constants } from '../../constants';
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
      return {
        url: require('../../assets/images/1_Justin_GettyImages-985138634@3x.jpg'),
      }
    },
    components: {
      BasicChatElement,
      BasicContextElement,
      BasicMediaElement
    },
    computed: {
      ...mapGetters(['currentArchivedChat', 'archivedCurrentChatUserMember']),
      getMember() {
      return this.currentArchivedChat.ChannelUsers
        ? this.currentArchivedChat.ChannelUsers.filter(
            (user) => user.UserRoleName === 'Member'
          )[0]
        : '';
    },
      getAdvisor() {
        return this.currentArchivedChat.ChannelUsers ? this.currentArchivedChat.ChannelUsers.filter(user => user.UserRoleName === 'Advisor')[0] : '';
      }
    },
    methods: {
      ...mapActions(['sendEmailTranscriptMember', 'sendEmailTranscriptAdvisor']),

      isContextualRequest(command) {
        switch(command) {
          case constants.REQUEST_TRANSFER:
          case constants.REQUEST_ACCEPTED:
          case constants.REQUEST_REJECTED:
          case constants.REQUEST_END:
          case constants.WARNING_TO_CONTINUE:
          case constants.CTA_FOR_MEMBER_IN_QUEUE:
          case constants.CTA_FOR_MEMBER_ADVISOR_BUSY:
          case constants.CTA_FOR_MEMBER_MEMBER_IDLE:
          case constants.CTA_FOR_MEMBER_OUTSIDE_OFFICE_HOURS:
            return true;
          
          default:
            return false;
        }
      },

      emailTranscriptMember() {
        this.sendEmailTranscriptMember();
      },
      emailTranscriptAdvisor() {
        this.sendEmailTranscriptAdvisor();
      }
    },
}
</script>
<style scoped>
.archive-chat-content {
  @apply w-full flex flex-col;
  height: 90%;

  &__container {
    @apply w-full h-full flex flex-col overflow-scroll overflow-x-hidden;

    .no-chat-selected {
      @apply flex justify-center opacity-50 items-center;
      height: 85%;
      font-size: 24px;
    }
  }

  &__message {
    @apply p-7 py-3 ml-7;

    .archive-chat-content__message-container--right & {
      @apply mr-7 ml-0;
    }
    
    &-container {
      @apply w-full;

      &--right {
        @apply ml-auto mr-0 pl-0 pr-12;
      }
    }

    &--advisor {
      @apply bg-blue-bubble text-white;
    }
  }
  
  &__bottom-sticky {
    @apply flex pt-4;
    align-items: center;
  }

  &__button {
    @apply bg-green-chat-lighter text-white uppercase font-kohinoor-bold text-small-chat py-4 ml-12 mb-4 w-full duration-100;
    max-width: 200px;
    
    &:hover {
      @apply  bg-grey-button text-green-chat-lighter;
    }
  }
}

.avatar {
  @apply font-kohinoor-light mt-7;
  font-size: 1.8rem !important ;

  &--advisor {
    @apply text-green-chat;
  }
}
</style>
<template>
  <div class="dashboard">
    <dash-side-bar />
    <div class="dashboard__content">
      <BasicTopNotification />
      <chats-header />
      <div class="chats">
        <chats-actives />
        <chats-content @scrollToEndFn="scrollToEnd()"/>
      </div>
    </div>
  </div>
</template>

<script>
import DashSideBar from '../DashSidebar';
import ChatsHeader from './ChatsHeader';
import ChatsActives from './ChatsActives';
import ChatsContent from './ChatsContent';
import BasicTopNotification from '../Basic/BasicTopNotification';
import { mapActions } from 'vuex';
export default {
  data() {
    return {
    };
  },
  components: {
    DashSideBar,
    ChatsHeader,
    ChatsActives,
    ChatsContent,
    BasicTopNotification
  },
  methods: {
    ...mapActions(['setMessagesContainer']),
     scrollToEnd() {
      const content = this.$el.querySelector(".chats-content__container");
      content.scrollTop = content.scrollHeight;
    }
  },
   mounted() {
     this.setMessagesContainer(this.$el.querySelector(".chats-content__container"));
  },
 
};
</script>

<style scoped>
.chats {
  @apply h-full flex overflow-hidden;
}

.dashboard__content {
  @apply overflow-hidden;
}
</style>

<template>
  <div class="chats-content">
    <div class="chats-content__container" ref="contentContainer">
      <div class="no-chat-selected" v-if="currentChat === undefined">
        No chat selected
      </div>
      <div
        v-else
        class="chats-content__message-container"
        v-for="(message, index) in currentChat.ChatMessages"
        :key="index"
      >
        <BasicChatElement
          v-if="message.Command === 'Message'"
          :messageProp="message"
          :advisor="getAdvisor"
          :member="getMember"
        />
        <BasicContextElement
          v-if="isContextualRequest(message.Command)"
          :messageProp="message"
          :chatProp="currentChat"
        />
        <BasicMediaElement
          v-if="message.Command === 'Document' || message.Command === 'Media'"
          :messageProp="message"
          :advisor="getAdvisor"
          :member="getMember"
        />
      </div>
    </div>
    <modals-container name="ChatsTransfer" />
    <div class="chats-content__send-bubble">
      <div class="chats-content__wrapper">
        <div class="chats-content__progress-bar" ref="container"></div>
        <section
          class="chats-content__bubble"
          v-bind:class="{ error: sendErrorBool }"
        >
          <textarea
            class="chats-content__input"
            type="input"
            placeholder="Type a message..."
            v-model="newMessage"
            @keyup.enter.exact="sendChatMessage"
            :disabled="!isChatActive()"
          ></textarea>
          <div class="chats-content__icon-container">
            <attachment class="chats-content__icon" />
            <input
              ref="file"
              name="Select File"
              type="file"
              class="chats-content__input-file"
              @change="uploadFile"
              :disabled="!isChatActive()"
            />
          </div>
        </section>
      </div>
      <div class="chats-content__button-container">
        <button
          v-if="isChatActive()"
          class="chats-content__button"
          :disabled="!currentChat"
          @click.prevent="sendChatMessage"
        >
          send
        </button>
        <label class="send-error-label" v-if="sendErrorBool">{{
          sendErrorMessage
        }}</label>
        <button
          v-if="isChatActive()"
          class="chats-content__button chats-content__button--exit"
          :disabled="!currentChat"
          @click.prevent="showExitModal"
        >
          End Chat
        </button>
        <button
          v-if="!isChatActive()"
          class="chats-content__button chats-content__button--exit chats-content__button--exit-secondary"
          :disabled="!currentChat"
          @click.prevent="exitChat"
        >
          Close Chat
        </button>

        <button
          v-if="isChatActive()"
          class="chats-content__button chats-content__button--transfer"
          :disabled="!currentChat"
          @click.prevent="showModal"
        >
          transfer chat
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import ChatsTransfer from './ChatsTransfer';
import BasicConditionalModal from '../Basic/BasicConditionalModal';
import Attachment from '../../assets/icons/Attachment.svg';
import ChatsProgressBar from './ChatsProgressBar';

import BasicChatElement from '../Basic/BasicChatElement';
import BasicContextElement from '../Basic/BasicContextElement';
import BasicMediaElement from '../Basic/BasicMediaElement';
import { constants } from '../../constants';
import PrettyBytes from '../../filters/PrettyBytes';

export default {
  name: 'chatContent',

  data() {
    return {
      url: require('../../assets/images/1_Justin_GettyImages-985138634@3x.jpg'),
      items: [],
      newMessage: '',
      uploadPercentage: 0,
      file: '',
      currentInstance: '',
      sendErrorBool: false,
      sendErrorMessage: '',
    };
  },
  components: {
    Attachment,
    ChatsProgressBar,
    BasicChatElement,
    BasicMediaElement,
    BasicContextElement,
  },

  filters: {
    PrettyBytes,
  },

  computed: {
    ...mapGetters([
      'currentChat',
      'transactionGroupId',
      'currentUsers',
      'progressBarInstances',
      'attachements'
    ]),
    getMember() {
      return this.currentChat.ChannelUsers
        ? this.currentChat.ChannelUsers.filter(
            (user) => user.UserRoleName === 'Member'
          )[0]
        : '';
    },
    getAdvisor() {
      return this.currentChat.ChannelUsers
        ? this.currentChat.ChannelUsers.filter(
            (user) => user.UserRoleName === 'Advisor'
          )[0]
        : '';
    },
  },
  methods: {
    ...mapActions([
      'sendMessage',
      'submitFile',
      'removeFile',
      'pushProgressBarInstance',
      'endChannel',
      'setAdvisorStateFilter',
      'exitChannel',
    ]),

    isChatActive() {
      if (this.currentChat && this.currentChat.ChatMessages.length > 0) {
        return (
          this.currentChat.ChatMessages[
            this.currentChat.ChatMessages.length - 1
          ].Command === 'Message' ||
          this.currentChat.ChatMessages[
            this.currentChat.ChatMessages.length - 1
          ].Command === 'Media' ||
          this.currentChat.ChatMessages[
            this.currentChat.ChatMessages.length - 1
          ].Command === 'Document' ||
          this.currentChat.ChatMessages[
            this.currentChat.ChatMessages.length - 1
          ].Command === 'RequestTransfer' ||
          this.currentChat.ChatMessages[
            this.currentChat.ChatMessages.length - 1
          ].Command === 'RequestAccepted' ||
          this.currentChat.ChatMessages[
            this.currentChat.ChatMessages.length - 1
          ].Command === 'RequestRejected'
        );
      } else {
        return true;
      }
    },

    isUserAdvisor(userId) {
      return this.currentChat.ChannelUsers.filter(
        (item) => item.UserId === userId && item.UserRoleName === 'Advisor'
      ).length > 0
        ? true
        : false;
    },
    isContextualRequest(command) {
      switch (command) {
        case constants.REQUEST_TRANSFER:
        case constants.REQUEST_ACCEPTED:
        case constants.REQUEST_REJECTED:
        case constants.REQUEST_END:
        case constants.WARNING_TO_CONTINUE:
        case constants.CTA_FOR_MEMBER_IN_QUEUE:
        case constants.CTA_FOR_MEMBER_ADVISOR_BUSY:
        case constants.CTA_FOR_MEMBER_MEMBER_IDLE:
        case constants.CTA_FOR_MEMBER_OUTSIDE_OFFICE_HOURS:
          return true;

        default:
          return false;
      }
    },

    GetValidAttachements() {
      return this.attachements.filter(
        (x) => x.chatId == this.currentChat.Id && !x.isSent
      );
    },
    sendNormalMessage(activeAdvisor) {
      this.sendMessage({
        ChannelId: activeAdvisor.ChannelId,
        UserId: activeAdvisor.UserId.toLowerCase(),
        UserDisplayName: activeAdvisor.UserDisplayName.toLowerCase(),
        Message: this.newMessage,
        Command: 'Message',
      }).then(
          (successfullSent) => { 
            if (successfullSent) {
              this.newMessage = '';
            }
      });
      
    },
    sendAttachmentMessage(activeAdvisor, validAttachements) {
      for (let index = 0; index < validAttachements.length; index++) {
        this.sendMessage({
          ChannelId: activeAdvisor.ChannelId,
          UserId: activeAdvisor.UserId.toLowerCase(),
          UserDisplayName: activeAdvisor.UserDisplayName.toLowerCase(),
          Message: '',
          Command: 'Message',
          TransactionGroupId: validAttachements[index].transactionId,
        });
      }
    },
    sendChatMessage() {
      this.sendErrorBool = false;
      this.sendErrorMessage = '';

      const activeAdvisor = this.getAdvisor;
      const validAttachements = this.GetValidAttachements();

      if (
        (this.newMessage.length === 0 || !this.newMessage.trim()) &&
        validAttachements.length === 0
      ) {
        this.sendErrorBool = true;
        this.sendErrorMessage = 'Message must not be empty';
        return false;
      }

      if (validAttachements.length) {
        this.sendAttachmentMessage(activeAdvisor, validAttachements);
      }

      if (this.newMessage !== '') {
        this.sendNormalMessage(activeAdvisor);
      }
    },

    showModal() {
      this.setAdvisorStateFilter('1');
      this.$modal.show(
        ChatsTransfer,
        {
          channelId: this.currentChat.Id,
        },
        {
          name: 'chats-transfer',
          draggable: false,
          scrollable: true,
          height: 'auto',
          width: '935px',
        }
      );
    },
    showExitModal() {
      this.$modal.show(
        BasicConditionalModal,
        {
          title: 'Are you sure?',
          message:
            'Clicking yes will end this conversation and cannot be opened again.',
          handleClick: () => {
            this.endChannel();
          },
        },
        {
          draggable: false,
          height: 'auto',
          width: '50%',
        }
      );
    },
    exitChat() {
      const chatDetails = {
        channelId: this.currentChat.Id,
      };

      this.exitChannel(this.currentChat.Id);
    },
    uploadFile() {
      const config = {
        header: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (ProgressEvent) => {
          this.uploadPercentage = parseInt(
            Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
          );
          this.currentInstance.$props.uploadPercentage = this.uploadPercentage;
        },
      };

      const activeAdvisor = this.getAdvisor;
      this.file = this.$refs.file.files[0];
      const ComponentClass = Vue.extend(ChatsProgressBar, this.$store);
      const instance = new ComponentClass({
        propsData: {
          uploadPercentage: this.uploadPercentage,
          errors: [],
          transactionId: '',
          currentChat: this.currentChat,
          isLoaded: false,
          file: this.file,
          currentInstance: this.currentInstance,
          removeFile: this.removeFile,
          isVisible: true,
          sendError: false,
          errorMessage: '',
        },
      });
      instance.$slots.uploading = `Uploading ${
        this.file.name
      } ${this.$options.filters.PrettyBytes(this.file.size)}`;
      instance.$slots.uploaded = `Uploaded ${
        this.file.name
      } ${this.$options.filters.PrettyBytes(this.file.size)}`;
      instance.$slots.error = `${
        this.file.name
      } ${this.$options.filters.PrettyBytes(this.file.size)}`;
      instance.$mount();
      this.currentInstance = instance;
      this.$refs.container.appendChild(instance.$el);

      const isBiggerThanAllowed = this.file.size > 10240000;
      const hasWrongFileType =
        this.file.type != 'image/jpeg' &&
        this.file.type != 'image/png' &&
        this.file.type != 'application/pdf';
      if (isBiggerThanAllowed || hasWrongFileType) {
        instance.$props.sendError = true;
        instance.$props.errorMessage =
          'Oops, your upload failed. Check the file size (10MB limit) and type (PDF or JPG) and try again.';
        instance.$props.isLoaded = true;
        instance.$props.uploadPercentage = 0;
      } else {
        instance.$props.sendError = false;
        instance.$propserrorMessage = '';

        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('UserId', activeAdvisor.UserId);
        formData.append('ChannelId', activeAdvisor.ChannelId);
        formData.append('UserDisplayName', activeAdvisor.UserDisplayName);
        formData.append('UserRole', 'Advisor');

        const configFile = {
          config,
          formData,
          instance,
        };

        this.submitFile(configFile);
        this.pushProgressBarInstance(instance);
        this.$refs.file.value = null;
      }
    },
  },
};
</script>

<style scoped>
.chats-content {
  @apply w-full flex flex-col;

  &__container {
    @apply w-full flex flex-col overflow-scroll overflow-x-hidden px-10;

    .no-chat-selected {
      @apply h-full flex justify-center opacity-50 items-center mt-32;

      font-size: 24px;
    }
  }

  &__wrapper {
    @apply mt-12;
  }

  &__message {
    @apply p-7 py-3 ml-7;

    .chats-content__message-container--right & {
      @apply mr-7 ml-0;
    }

    &-container {
      @apply w-full;

      &--right {
        @apply ml-auto mr-0 pl-0 pr-12;
      }
    }

    &--advisor {
      @apply bg-blue-bubble text-white;
    }
  }

  &__icon {
    @apply cursor-pointer;

    &-container {
      @apply relative;
    }
  }

  &__send-bubble {
    @apply sticky mt-auto;
  }

  &__bubble {
    @apply border border-solid border-grey-divider mt-6 mx-12 rounded-bubble mb-4 p-7;
    min-height: 145px;

    &.error {
      border-color: #e53e3e;
    }
  }

  &__thumbnail {
    @apply w-full h-full rounded-bubble;
    max-width: 233px;

    &-container {
      @apply flex flex-col items-end mr-24 ml-0 mt-2;
    }

    &-text {
      @apply uppercase text-center bg-blue-bubble text-white underline cursor-pointer rounded-bubble mt-2 p-7 ml-7;
    }
  }

  &__input {
    @apply w-full h-full resize-none;
    min-height: 100px;
    &:focus {
      @apply outline-none;
    }

    &-file {
      @apply absolute left-0 top-0 opacity-0 cursor-pointer;
    }
  }

  &__button {
    @apply bg-grey-button text-green-chat-lighter uppercase font-kohinoor-bold text-small-chat py-4 ml-12 mb-4 w-full duration-100;
    max-width: 139px;

    &:hover {
      @apply bg-green-chat-lighter text-white;
    }

    &:disabled {
      @apply opacity-50;

      &:hover {
        @apply bg-grey-button text-green-chat-lighter;
      }
    }

    &--transfer {
      @apply mr-12 ml-0;
    }

    &--exit {
      @apply ml-auto mr-8;

      &-secondary {
        @apply mr-12;
      }
    }

    &-container {
      @apply flex justify-between;

      .send-error-label {
        @apply flex text-red-alert text-xl px-8 pb-3;
        align-items: center;
      }
    }
  }
}

.avatar {
  @apply font-kohinoor-light mt-7;
  font-size: 1.8rem !important ;

  &--advisor {
    @apply text-green-chat;
  }
}
</style>

export const actionTypes = {

    // auth
    SET_REFRESHING_STATE: 'SET_REFRESHING_STATE',
    SET_REFRESHING_CALL: 'SET_REFRESHING_CALL',
    SET_MESSAGEBOX: 'SET_MESSAGEBOX',
    SET_NOTIFICATION_STATE: 'SET_NOTIFICATION_STATE',
    SET_REMEMBER_ME: 'SET_REMEMBER_ME',

    // chats
    SET_CHATS: 'SET_CHATS',
    SET_ACTIVE_CHAT: 'SET_ACTIVE_CHAT',
    SEND_MESSAGE: 'SEND_MESSAGE',
    SET_CHAT_NOTIFICATION_WATCHER: 'SET_CHAT_NOTIFICATION_WATCHER',
    REMOVE_CHAT_NOTIFICATION_WATCHER: 'REMOVE_CHAT_NOTIFICATION_WATCHER',
    ADD_FILE: 'ADD_FILE',
    REMOVE_FILE: 'REMOVE_FILE',
    SET_MODAL_CONTEXT: 'SET_MODAL_CONTEXT',
    REMOVE_MODAL: 'REMOVE_MODAL',
    REMOVE_CONFIRM_MODAL:'REMOVE_CONFIRM_MODAL',
    REMOVE_MODAL_CONTEXT: 'REMOVE_MODAL_CONTEXT',
    SET_MODAL_RUNNING_STATE: 'SET_MODAL_RUNNING_STATE',
    SET_REQUESTED_USERS: 'SET_REQUESTED_USERS',
    CLEAR_ALL_CHATS: 'CLEAR_ALL_CHATS',
    PUSH_PROGRESS_BAR_INSTANCE: 'PUSH_PROGRESS_BAR_INSTANCE',
    REMOVE_PROGRESS_BAR_INSTANCES: "REMOVE_PROGRESS_BAR_INSTANCES",
    PUSH_ATTACHEMENT: 'PUSH_ATTACHEMENT',
    MARK_SENT_ATTACHEMENT: 'MARK_SENT_ATTACHEMENT',
    SET_MESSAGES_CONTAINER: 'SET_MESSAGES_CONTAINER',

    
    // activities
    SET_ACTIVITIES: 'SET_ACTIVITIES',
    SET_ACTIVITY_FILTER: 'SET_ACTIVITY_FILTER',
    CLEAR_ALL_ACTIVITIES: 'CLEAR_ALL_ACTIVITIES',

    // archives
    SET_INITIAL_SEARCH_STATE: 'SET_INITIAL_SEARCH_STATE',
    SET_ARCHIVED_RESULTS: 'SET_ARCHIVED_RESULTS',
    SET_ACTIVE_ARCHIVE_CHAT: 'SET_ACTIVE_ARCHIVE_CHAT',
    SET_SEARCH_KEYWORD: 'SET_SEARCH_KEYWORD',
    RESET_CURRENT_ACTIVE_ARCHIVE_CHAT: 'RESET_CURRENT_ACTIVE_ARCHIVE_CHAT',
    CLEAR_ALL_ARCHIVES: 'CLEAR_ALL_ARCHIVES',

    // advisors
    GET_ADVISORS: 'GET_ADVISORS',
    SET_ACTIVE_ADVISOR: 'SET_ACTIVE_ADVISOR',
    SET_ADVISOR_FILTER: 'SET_ADVISOR_FILTER',
    CLEAR_ALL_ADVISORS: 'CLEAR_ALL_ADVISORS',
    GET_USERS: 'GET_USERS',

    // profile
    GET_ADVISOR_BY_NAME: 'GET_ADVISOR_BY_NAME',
    ADD_PROFILE_IMAGE: 'ADD_PROFILE_IMAGE',
    SET_TRANSFER_ADVISOR: 'SET_TRANSFER_ADVISOR',
    CLEAR_PROFILE: 'CLEAR_PROFILE',

    // transfer
    CLEAR_ALL_TRANSFER_STATES: 'CLEAR_ALL_TRANSFER_STATES'
}
import Vue from 'vue';
import {
  getAdvisorByUserName,
  uploadImage,
  editAdvisor,
} from '../../api/profile-api/api';
import api from '../../api/auth/api';
import { actionTypes } from '../actionTypes';
import router from '../../router';
import LocalStorageService from '../../services/LocalStorageService';

const state = {
  auth: {
    isRefreshing: false,
    refreshingCall: null,
    loginMessagebox: '',
  },
  notification: {
    show: false,
    isError: false,
    message: ''
  },
  rememberMeStore: false,
  advisorByName: {
    Advisor: {
      UserDisplayName: '',
      UserId: '',
      UserRole: '',
      Description: '',
      PictureUrl: '',
      SpecialistAdviceAreas: '',
      Email: '',
      MemberCategory: '',
    },
  },
  files: [],
};

const getters = {
  userDetails: (state) => ({userId: state.userId, userRoleName: state.userRoleName}),
  advisorByName: (state) => state.advisorByName,
  loginMessagebox: (state) => state.auth.loginMessagebox,
  notificationDetails: (state) => state.notification,
  rememberMeStore: (state) => state.rememberMeStore
};

const actions = {
  setRememberMe({commit}, setRememberMe) {
    commit(actionTypes.SET_REMEMBER_ME, setRememberMe);  
  },
  async login({ commit, dispatch }, loginDetails) {
    let response;
  
    try {
      response = await api.auth.login(loginDetails);

        commit(actionTypes.SET_MESSAGEBOX, '');
        LocalStorageService.setLocalStorageDetails({
          token: response.data.token,
          UserName: loginDetails.UserName.toLowerCase(),
        });

        dispatch('getAdvisorByUserName', loginDetails.UserName.toLowerCase());
        dispatch('initialiseActivitiesServices');
        dispatch('initialiseChatServices');
        dispatch('initialiseAdvisorsServices');
        router.push({ path: '/' });
    } catch (error) {
      if (error.response.status === 403) {
        Vue.$log.error('Error - Login - Unable to login:', error);
        commit(actionTypes.SET_MESSAGEBOX, 'Unable to Login, please check login credentials and try again');
        localStorage.removeItem('mdaChatToken');
      } else {
        Vue.$log.error('Error: -Login - Login Error: ', error);
        commit(actionTypes.SET_MESSAGEBOX, '');
      }
    }
  },

  async logOff({ rootState }) {
    let response;
    try {
      response = await api.auth.logOut({ UserName: rootState.Profile.advisorByName.Advisor.UserId});
      localStorage.removeItem('mdaChatToken');
      router.push({ path: '/login' });
    } catch (error) {
      Vue.$log.error('Error - LogOff: ', error);
    }
  },

  async getAdvisorByUserName({ commit }, user) {
    let response;
    try {
      response = await getAdvisorByUserName.getAdvisorByName(user);
      commit(actionTypes.GET_ADVISOR_BY_NAME, response.data);
    } catch (error) {
      Vue.$log.error('Error - getAdvisorByUserName: ', error);
    }
  },

  async submitProfileImage({ commit, dispatch, rootState }, file) {
    uploadImage
      .addFile(file)
      .then(function() {
        commit(actionTypes.ADD_PROFILE_IMAGE, file);
        setTimeout(function() {
          const userProfile = rootState.Profile.advisorByName.Advisor.UserId;
          dispatch('getAdvisorByUserName', userProfile);
        }, 500);
      })
      .catch(function(error) {
        Vue.$log.error('Error - submitProfileImage: ', error);
      });
  },

  editProfileInfo({ dispatch }, info) {
    editAdvisor.editAdvisorProfile(info)
      .then(function() {
        dispatch('getAdvisorByUserName', info.UserId);
      })
      .catch(function(error) {
        Vue.$log.error('Error - editProfileInfo: ', error);
      });
  },
  clearServerErrorNotification({ commit, state }, notificationDetails) {
    if (state.notification.id === 'SERVER_ERROR') {
      commit(actionTypes.SET_NOTIFICATION_STATE, notificationDetails);
    }
  },
  cleanNotifications({ commit }) {
    commit(actionTypes.SET_NOTIFICATION_STATE, null);

  },
  setNotification({ commit }, notificationDetails) {
    commit(actionTypes.SET_NOTIFICATION_STATE, notificationDetails);
    setTimeout(function() {
      if (notificationDetails.id != 'SERVER_ERROR') {
        commit(actionTypes.SET_NOTIFICATION_STATE, { show: false, isError: notificationDetails.isError , message: notificationDetails.message });
      }
    }, 3000);
  },

  setRefreshingState({ commit }, state) {
    commit(actionTypes.SET_REFRESHING_STATE, state);
  },

  setRefreshingCall({ commit }, call) {
    commit(actionTypes.SET_REFRESHING_CALL, call);
  },

  setMessageBox({ commit }, message) {
    commit(actionTypes.SET_MESSAGEBOX, message);
  },

  clearProfile({ commit }) {
    commit(actionTypes.CLEAR_PROFILE);
  }

};

const mutations = {
  SET_REMEMBER_ME(state, value) {
    if (value) {
      LocalStorageService.setLocalStorageItem('RememberMe', 'true');
      state.rememberMeStore = true;
    } else {
      LocalStorageService.setLocalStorageItem('RememberMe', 'false');
      state.rememberMeStore = false;
    }
  },
  SET_REFRESHING_STATE(state, refreshingState) {
    state.auth.isRefreshing = refreshingState;
  },

  SET_REFRESHING_CALL(state, call) {
    state.auth.refreshingCall = call;
  },

  SET_MESSAGEBOX(state, message) {
    state.auth.loginMessagebox = message;
  },

  SET_NOTIFICATION_STATE(state, notificationDetails) {
    if (notificationDetails != null) {
      state.notification = notificationDetails;
    } else {
      state.notification = { show: false, isError: false, message: ''};
    } 
  },

  GET_ADVISOR_BY_NAME(state, user) {
    state.advisorByName = user;
    user.MemberCategory === ''
      ? (state.advisorByName.Advisor.MemberCategory = 'Advisor')
      : [];
  },

  ADD_PROFILE_IMAGE(state, file) {
    state.files.push(file);
  },

  CLEAR_PROFILE(state) {
    state.auth = {
      isRefreshing: false,
      refreshingCall: null,
      loginMessagebox: '',
    };
    state.notification = {
      show: false,
      isError: false,
      message: ''
    };
    state.advisorByName = {
      Advisor: {
        UserDisplayName: '',
        UserId: '',
        UserRole: '',
        Description: '',
        PictureUrl: '',
        SpecialistAdviceAreas: '',
        Email: '',
      },
    };
    state.files = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

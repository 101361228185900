<template>
  <div class="archive-search">
      <basic-header title="Archive"/>
      <div class="archive-search__search-container">
          <div class="archive-search__heading">
            Enter Chat Reference Number or Member Number to search
          </div>
          <archive-search-input />
          <div class="archive-search__info">
            Search either by the Chat Reference Number, by Member Number, or by Date. <br>
            Date format should be DD/MM/YYYY
          </div>
      </div>
  </div>
</template>

<script>
import BasicHeader from '../Basic/BasicHeader';
import ArchiveSearchInput from './ArchiveSearchInput';

export default {
  data() { 
    return {
    };
  },
  components: {
    BasicHeader,
    ArchiveSearchInput
  },
}
</script>

<style scoped>
.archive-search {
    @apply h-full;

    &__search-container {
      @apply h-full flex overflow-hidden bg-grey-active flex-col ;
    }

    &__heading {
      @apply w-full text-center font-kohinoor-med font-black mb-12;
      margin-top: 80px;
      font-size: 18px;
      font-weight: 600;
      padding-right: 120px;
    }

    &__info {
      @apply w-full text-center font-kohinoor-light mt-20 opacity-75;
    }
}

.dashboard__content {
  @apply overflow-hidden;
}
</style>

import Vue from 'vue';
import { actionTypes } from '../actionTypes';
import { getActivities } from '../../api/activities-api/api';
import router from '../../router/index';
import TabNotificationService from '../../services/TabNotificationService';
let activitiesInterval;

const state = {
  activities: [], //Entire Blob
  currentChatFilter: 'All',
  currentActivities: [],
  seenQueuedChats: []
};

const getters = {
  activities: (state) => state.activities,
  currentChatFilter: (state) => state.currentChatFilter,
  currentActivities: (state) => state.currentChatFilter === 'All' ? state.activities : state.activities.filter((x) => x.Status === state.currentChatFilter),
  seenQueuedChats: (state) => state.seenQueuedChats
};

const actions = {
  async initialiseActivitiesServices({ dispatch }) {
    dispatch('fetchActivities');
    activitiesInterval = setInterval(function () {
      dispatch('fetchActivities');
    }, 5000);
  },

  async clearActivitiesServices() {
    clearInterval(activitiesInterval);
   },

  async fetchActivities({ commit,state }) {
    let response;

    try {
      response = await getActivities.getAll();
      commit(actionTypes.SET_ACTIVITIES, response.data);

    } catch (error) {
      Vue.$log.error('Error - fetchActivities: ', error);
    }
  },

  setActivityFilter({ commit }, filter) {
    commit(actionTypes.SET_ACTIVITY_FILTER, filter);
  },

  async joinNewChat({ commit }, channel) {
    let response;

    try {
      response = await getActivities.joinChat(channel);
      //commit(actionTypes.SET_ACTIVITIES, channel);
      commit(actionTypes.SET_ACTIVE_CHAT, channel.ChannelId);
      router.push(`/chats/${channel.ChannelId}`);

    } catch (error) {
      Vue.$log.error('Error - joinNewChat: ', error);
    }
  },

  clearActivities({ commit }) {
    commit(actionTypes.CLEAR_ALL_ACTIVITIES);
  },
};

const mutations = {
  SET_ACTIVITIES(state, activities) {
    var isTabHidden = document.hidden;
    var queuedToNotify = 0;
    if (isTabHidden || router.history.current.name != 'Main') {

        activities.filter(act => act.Status === 'Queue').forEach( activity => {
            if (state.seenQueuedChats.filter( seenQueuedChat => seenQueuedChat.Id == activity.Id).length == 0) {
              queuedToNotify += 1;
            }
        });


        
    } else {
      state.seenQueuedChats = activities.filter(activity => activity.Status === 'Queue');
    }
    state.activities = activities;
    

    if (isTabHidden) {
      TabNotificationService.setNotification(queuedToNotify, "queue");
    } else {
      TabNotificationService.setNotification(queuedToNotify, "queue");
      TabNotificationService.setTitle();
    }
    
    
    
  },
  SET_ACTIVITY_FILTER(state, filter) {
    state.currentChatFilter = filter;
  },
  CLEAR_ALL_ACTIVITIES(state) {
    state.activities = [];
    state.currentChatFilter = 'All';
    state.currentActivities = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

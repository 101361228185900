<template>
  <div class="archive-results">
    <archive-results-header />
    <div class="archive-results__result-container">
      <div class="chat-results">
        <archive-search-input mini />
        <div class="chat-results__results">
          <div class="no-results" v-if="archivedChatsCount === 0">
            No Results Found
          </div>
          <div
            class="chat-card"
            :class="{ 'chat-card--active': chat.Id === currentArchivedChat.Id }"
            v-else
            v-for="(chat, index) in archivedChats"
            :key="'chat-' + index"
            v-on:click="setActiveChat(chat)"
          >
            <div class="chat-card__heading">
              <div class="chat-card__heading chat-card__heading--strong">
                {{ chat.ChannelUsers.filter(user => user.UserRoleName.toLowerCase() === 'member' || user.UserRoleName.toLowerCase() === 'advisor')[0].UserDisplayName }}
              </div>
              <div class="chat-card__heading chat-card__heading--light">
                {{ chat.Updated !== null ? timestamp(chat.Updated) : 'N/A' }}
              </div>
            </div>
            <div class="chat-card__message">
              {{
                chat.ChatMessages !== null
                  ? chat.ChatMessages[0].Message
                  : 'No messages available'
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="chat-content">
        <archive-chat-content />
      </div>
    </div>
  </div>
</template>

<script>
import ArchiveResultsHeader from './ArchiveResultsHeader';
import ArchiveSearchInput from './ArchiveSearchInput';
import ArchiveChatContent from './ArchiveChatContent';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      showError: false,
      errorMessage: 'Field must not be empty',
    };
  },
  components: {
    ArchiveResultsHeader,
    ArchiveChatContent,
    ArchiveSearchInput,
  },
  computed: {
    ...mapGetters(['archivedChats', 'archivedChatsCount', 'userDetails', 'currentArchivedChat']),
  },
  methods: {
    ...mapActions(['setActiveArchiveChat']),

    timestamp(date) {
      return moment
        .utc(date)
        .local()
        .fromNow();
    },

    setActiveChat(chat) {
      this.setActiveArchiveChat({
        ChannelId: chat.Id,
        UserRole: 'Advisor',
      });
    },
  },
};
</script>

<style scoped>
.archive-results {
  @apply h-full;

  &__result-container {
    @apply h-full flex overflow-hidden bg-white;

    .chat-results {
      @apply bg-grey-active h-full w-full;
      max-width: 440px;

      &__search {
        @apply mx-auto mt-10 px-12;

        input {
          @apply w-full mx-auto flex bg-white pl-12 border border-solid border-grey-divider text-mid-chat;
          height: 44px;
          max-width: 664px;
          border-radius: 15px;
          line-height: 1.29;

          &::placeholder {
            @apply text-black;
          }
        }
      }

      &__results {
        @apply mx-auto pt-6 overflow-y-scroll;
        height: 81%;
        
        .chat-card {
          @apply pr-12 pl-17 text-mid-chat border-b border-solid border-grey-archive flex flex-col justify-center;
          height: 70px;

          &--active {
            @apply border-none bg-green-chat-lighter text-white;

             .chat-card__heading--light {
              @apply text-white;
            }
          }

          &__heading {
            @apply flex justify-between pl-0 pr-6 text-mid-chat text-small-chat;

            &--strong {
              @apply font-kohinoor-med;
            }

            &--light {
              @apply text-grey-advisor pr-17;

            }
          }

          &__message {
            @apply whitespace-no-wrap overflow-hidden truncate;
            max-width: 84%;
          }

          &:hover {
            @apply bg-green-chat-lighter text-white cursor-pointer;

            .chat-card__heading--light {
              @apply text-white;
            }
          }
        }

        .no-results {
          @apply flex justify-center pt-40 font-kohinoor-demi opacity-50;
          font-size: 20px;
        }
      }
    }

    .chat-content {
      @apply bg-white w-full h-full;
    }
  }
}

.dashboard__content {
  @apply overflow-hidden;
}
</style>

<template>
  <div class="sidebar__container">
    <router-link to="/">
      <div class="sidebar__section">
        <activity-icon class="sidebar__icon" />
        <p class="sidebar__text">Activity</p>
      </div>
    </router-link>
    <router-link to="/chats">
      <div class="sidebar__section">
        <chat-icon class="sidebar__icon" />
        <p class="sidebar__text">Chats</p>
      </div>
    </router-link>
    <router-link to="/archive">
      <div class="sidebar__section" @click="setInitialState">
        <clock-icon class="sidebar__icon" />
        <p class="sidebar__text">Archive</p>
      </div>
    </router-link>
    <router-link to="/advisors">
      <div class="sidebar__section">
        <advisors-icon class="sidebar__icon" />
        <p class="sidebar__text">Advisers</p>
      </div>
    </router-link>
    <div class="sidebar__section sidebar__section--queued">
      <p>{{this.activities.filter(a => a.Status === 'Queue').length}} </p>
      <p class="sidebar__text">Queued</p>
    </div>
    <div class="sidebar__profile">
      <router-link to="/profile">
        <div class="sidebar__image-container">
          <avatar
            v-if="advisorByName.Advisor.PictureUrl != ''"
            :image="
              advisorByName.Advisor ? advisorByName.Advisor.PictureUrl : url
            "
            :size="54"
          ></avatar>
          <avatar
            v-if="
              advisorByName.Advisor.UserDisplayName &&
                advisorByName.Advisor.PictureUrl === ''
            "
            :fullname="
              advisorByName.Advisor.UserDisplayName
                ? advisorByName.Advisor.UserDisplayName
                : 'A'
            "
            :size="54"
            color="#105c46"
          ></avatar>
          <div class="sidebar__text sidebar__text--profile">
            Profile
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import ActivityIcon from '../assets/icons/Activity.svg';
import ChatIcon from '../assets/icons/Chat.svg';
import AdvisorsIcon from '../assets/icons/Advisors.svg';
import ClockIcon from '../assets/icons/Clock.svg';
import Avatar from 'vue-avatar-component';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      url: require('../assets/images/1_Justin_GettyImages-985138634@3x.jpg'),
    };
  },
  components: {
    ActivityIcon,
    ChatIcon,
    AdvisorsIcon,
    ClockIcon,
    Avatar,
  },
  name: 'SideBar',
  computed: {
    ...mapGetters(['advisorByName', 'activities']),
  },
  methods: {
    setInitialState() {
      this.$store.state.Archives.initialSearchState = false;
      this.$store.state.Archives.searchKeyword = '';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.sidebar {
  &__container {
    @apply bg-green-chat flex flex-col capitalize text-center text-white;
  }

  &__section {
    @apply py-9 px-9 flex flex-col items-center;
    &--queued {
      &:hover {
        @apply bg-green-chat !important;
      }
    }
    &:hover {
      @apply bg-green-chat-lighter;

      > .sidebar__text {
        @apply opacity-100;
      }
    }
  }

  &__icon {
    @apply stroke-current text-white stroke-2;
  }

  &__profile {
    @apply mt-auto;

    &:hover {
      @apply bg-green-chat-lighter;

      .sidebar__text {
        @apply opacity-100;
      }
    }
  }

  &__image {
    &-container {
      @apply flex flex-col justify-center items-center pt-8;
    }
  }

  &__text {
    @apply font-kohinoor-demi text-small-chat pt-2 opacity-50;

    &--profile {
      @apply pb-8;
    }
  }
}

.router-link-exact-active {
  @apply bg-green-chat-lighter;

  & .sidebar__text {
    @apply opacity-100;
  }

  & .sidebar__icon {
    @apply stroke-current text-green-progress;
  }

  > .sidebar__image-container {
    @apply bg-green-chat-lighter;
  }
}

.avatar {
  @apply object-fill;
}
</style>

<template>
  <div>
    <modals-container name="ActivityJoinChannelModal" />
    <table class="activity-result__table">
      <thead>
        <tr class="activity-result__wrapper">
          <th class="activity-result__section">Name</th>
          <th class="activity-result__section">Category</th>
          <th class="activity-result__section">Email</th>
          <th class="activity-result__section">Activity</th>
          <th class="activity-result__section">Adviser</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="activity in currentActivities"
          :key="activity.Id"
          :class="
            activity.Status === 'InProgress'
              ? 'activity-result__progress'
              : 'activity-result__queued'
          "
        >
          <td
            :class="activity.Status === 'InProgress'? 'activity-result__item activity-result__item--title' : 'activity-result__item'"
            v-if="GetActiveMember(activity)"
          >
            {{ items[`${activity.id}member`].UserDisplayName }}
          </td>
          <td class="activity-result__item">
            {{ items[`${activity.id}member`].MemberSpeciality }}
          </td>
          <td class="activity-result__item">
            {{ items[`${activity.id}member`].MemberEmail }}
          </td>
          <td class="activity-result__item">
            <div
              v-if="activity.Status === 'InProgress'"
              class="activity-result__active"
            >
              In progress
            </div>
            <button
              class="activity-result__button--alert"
              v-else
              @click="joinModal(activity)"
            >
              queued
            </button>
          </td>
          <td class="activity-result__item">
            <div v-if="GetActiveAdvisor(activity)">
              {{ items[`${activity.id}advisor`].UserDisplayName }}
            </div>
            <span v-else>-</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getActive } from '../mixins/getActive';
import ActivityJoinChannelModal from './ActivityJoinChannelModal';

export default {
  data() {
    return {
      items: []
    };
  },
  mixins: [getActive],
  methods: {
    ...mapActions(['joinNewChat']),

    joinModal(activity) {
      this.$modal.show( 
      ActivityJoinChannelModal,
      {
        activity
      },
        {
          draggable: false,
          height: '300px',
          width: '600px'
        },
        
      );
    }
  },
  computed: {
    ...mapGetters(['activities', 'currentActivities', 'advisorByName'])
  }
};
</script>

<style scoped>
.activity-result {
  &__table {
    @apply pt-16 w-full;
  }

  &__wrapper {
    @apply text-left;
  }

  &__section {
    @apply text-mid-chat font-kohinoor-med pt-17 pb-8  pl-17 font-normal capitalize;
  }

  &__item {
    @apply text-mid-chat font-kohinoor-demi py-8 pl-17;

    &:last-child {
      padding-right: 200px;
    }

    &--title {
      @apply font-kohinoor-med capitalize text-mid-chat;
    }
  }

  &__button {
    &--alert {
      @apply uppercase text-white bg-red-alert text-small-chat px-12 py-2 font-kohinoor-bold rounded-smaller;
    }
  }

  &__active {
    @apply text-green-chat font-kohinoor-med;
  }

  &__progress {
    @apply bg-grey-active border-b-1 border-white;
  }

  &__queued {
    @apply border-b-1 border-grey-divider;
  }
}
</style>

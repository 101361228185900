<template>
  <div class="chats-content__send-bubble">
    <h4 class="chats-content__title">{{ title }}</h4>
    <section class="chats-content__bubble">
      <textarea
        class="chats-content__input"
        type="input"
        placeholder="Type a message..."
        v-model="newMessage"
         @keyup.enter.exact="submitMessage"
      ></textarea>
    </section>
    <div class="chats-content__button-container">
      <button class="chats-content__button" v-on:click="submitMessage">send</button>
      <button
        class="chats-content__button chats-content__button--transfer"
        @click.prevent="$emit('close')"
      >cancel</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      newMessage: ""
    };
  },
  props: ["data", "title", "onSend", "closeModal"],
  methods: {
    submitMessage() {
      this.onSend(this.newMessage);
      this.$emit('close');  
      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
.chats-content {
  @apply w-full flex flex-col;

  &__title {
    @apply font-kohinoor-med text-title-chat pl-12;
  }

  &__send-bubble {
    @apply sticky mt-auto;
    padding: 74px 86px;
  }

  &__bubble {
    @apply border border-solid border-grey-divider mt-8 mx-12 rounded-bubble mb-10 p-7;
    min-height: 145px;
  }

  &__button {
    @apply bg-grey-button text-green-chat-lighter uppercase font-kohinoor-bold text-small-chat py-4 ml-12 mb-4 w-full duration-100;
    max-width: 139px;

    &:hover {
      @apply bg-green-chat-lighter text-white;
    }

    &--transfer {
      @apply mr-12 ml-0;
    }

    &-container {
      @apply flex justify-between;
    }
  }

  &__input {
    @apply w-full h-full resize-none;
    min-height: 100px;
    &:focus {
      @apply outline-none;
    }

    &-file {
      @apply absolute left-0 top-0 opacity-0 cursor-pointer;
    }
  }
}
</style>

<template>
  <div class="activity-header">
    <div class="activity-header__content">
      <div class="activity-header__section" @click="setAdvisorFilter('1')">Online ({{ filterOnline }})</div>
      <div class="activity-header__section" @click="setAdvisorFilter('0')">Offline ({{ filterOffline }})</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  
  data() {
    return {};
  },
  methods: {
    ...mapActions(['setAdvisorStateFilter']),
    setAdvisorFilter(filter) {
      this.setAdvisorStateFilter(filter)
    },
  },
  mounted() {
    this.setAdvisorStateFilter('1');
  },
  computed: {
    ...mapGetters(['users']),
    filterOnline() {
      if (this.users != undefined) {
        return this.users.filter(user => user.OnlineStatus === '1').length;
      } else {
        return 0;
      }
    },
    filterOffline() {
      if (this.users != undefined) {
        return this.users.filter(user => user.OnlineStatus === '0').length;
      } else {
        return 0;
      }
    }
  }
};
</script>

<style scoped>
.activity-header {
  @apply border-b-1 border-grey-divider;

  &__section {
    @apply pt-16 pb-8 font-kohinoor-demi text-mid-chat text-center duration-100 ml-17;
    width: 198px;

    &:hover {
      @apply font-kohinoor-med border-b-2 border-green-chat;
    }
  }

  &__content {
    @apply flex justify-start;
  }
}
</style>

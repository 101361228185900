<template>
  <div class="basic-header">
    <div class="basic-header__content">
      <div class="basic-header__section basic-header__section--strong">
        {{ title }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: ['title'],
}
</script>
<style scoped>
.basic-header {
  @apply border-b-1 border-grey-divider;

  &__section {
    @apply pt-16 pb-8 font-kohinoor-demi text-mid-chat text-center duration-100;
    padding-left: 4.5rem;

    &--strong {
      @apply font-kohinoor-med;
      padding-left: 4.5rem;
    }
  }

  &__content {
      @apply flex;
  }
}
</style>
<template>
  <div class="active-chat-card" v-bind:class="{ active: isActive} ">
    <avatar
      :fullname="memberDisplayName"
      :size="44"
      color="#e5f2ef"
    ></avatar>
    <div class="active-chat-card__message-content">
      <h4 class="active-chat-card__title">{{ memberDisplayName }}</h4>
      <p class="active-chat-card__text">{{ getLastMessage }}</p>
    </div>
  </div>
</template>
<script>
import Avatar from 'vue-avatar-component';

export default {
  data() {
    return {};
  },
  components: {
    Avatar
  },
  props: ["messageProp", "isActive"],
  computed: {
    memberDisplayName() {
      const member = this.messageProp.ChannelUsers.find(user => user.UserRoleName === 'Member');
      return member ? member.UserDisplayName : 'Anonymous';
    },
    getLastMessage() {
      if (this.messageProp.ChatMessages.length > 0 ) {
          const ignoredMessageCommands = ['RequestTransfer', 'RequestAccepted', 'RequestRejected'];
          let messagesFromUser = this.messageProp.ChatMessages.filter(
                                          message => ignoredMessageCommands.indexOf(message.Command) == -1);
          if (messagesFromUser.length > 0) {
            const lastCommand = messagesFromUser[messagesFromUser.length - 1].Command;
            if (lastCommand == "Document" || lastCommand == "Media") {
              return "Attachment";
            }
            return messagesFromUser[messagesFromUser.length - 1].Message;
          }
          return '';
      }
      return '';
    }
    
  }
};
</script>
<style scoped>

.active-chat-card {
  
  @apply w-full bg-white relative border border-solid border-avatar-light text-black rounded-bubble mt-6 flex items-center cursor-pointer;
  max-width: 352px;

  &.active {
    @apply bg-green-chat-lighter text-white;

    & > .avatar {
      @apply bg-green-chat text-white !important;
    }
  }

  & > .avatar {
    @apply text-green-chat !important;
  }

  &__message-content {

    @apply pl-4;

    &--active {
      @apply bg-green-chat-lighter text-white;
    }

    &:first-child {
      @apply mt-10;
    }

    &-content {
      @apply pl-6;
    }
  }

  &__warning {
    @apply text-red-alert pt-6;
  }

  &__title {
    @apply font-kohinoor-med text-small-chat;
  }

  &__text {
    @apply text-mid-chat font-kohinoor-demi truncate;
    max-width: 230px;
  }
}

.avatar {
  @apply font-kohinoor-light ml-8 my-7 text-green-chat;
  font-size: 1.8rem !important ;
}

</style>
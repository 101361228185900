<template>
  <div class="top-notification-component" v-bind:class="{ show: notificationDetails.show }">
    <div class="top-notification-component__container" v-bind:class="{ error: notificationDetails.isError }">
      <error class="error-icon" />
      {{ notificationDetails.message }}
    </div>
  </div>
</template>
<script>
import Error from "../../assets/icons/Error.svg";
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      testText: 'This is an error message'
    }
  },
  computed: {
    ...mapGetters(['notificationDetails'])
  },
  components: {
    Error
  },
};
</script>
<style lang="scss" scoped>
  .top-notification-component {
    @apply flex fixed w-full;
    height: 60px;
    top: -60px;
    transition: 1s;

    &.show {
      top: 0;
    }


    &__container {
      @apply flex font-kohinoor-med items-center w-full h-full px-20 text-white;
      background-color: #17e57d;
      font-size: 14px;

      &.error {
        background-color: #FF5963; 
      }

      .error-icon {
        @apply mr-8;
      }
    }
  }
</style>
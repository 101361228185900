<template>
  <div class="login-component">
    <div class="login-component__message-box" v-if="loginMessagebox != ''">
      {{ this.loginMessagebox }}
    </div>
    <div class="login-component__fields">
      <input
        type="text"
        v-model="userId"
        :class="userIdError ? 'error' : ''"
        placeholder="User ID"
      />
      <div class="input-error" v-if="userIdError">
        {{ this.userIdErrorText }}
      </div>
      <input
        type="password"
        v-model="password"
        :class="passwordError ? 'error' : ''"
        placeholder="Password"
      />
      <div class="input-error" v-if="passwordError">
        {{ this.userPassErrorText }}
      </div>
    </div>
    <div class="login-component__remember">
       <input  :value="rememberMe" v-model="rememberMe" @change="setRememberMeValue($event)" type="checkbox" />Remember me
    </div>
    <div class="login-component__controls">
      <button type="submit" @click="tryLogin">Log in</button>
    </div>
    <div class="login-component__forgot-password">
      <!-- Forgot your password? -->
      <p>
        For password resets, please email
        <a href="mailto:marketing@mdanational.com.au"
          >marketing@mdanational.com.au</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LocalStorageService from './../../services/LocalStorageService';
const emptyFieldText = 'Field should not be empty';
// const invalidLogin = 'Invalid login details. Please check and try again';

export default {
  data() {
    return {
      rememberMe: false,
      userId: '',
      password: '',
      userIdError: false,
      passwordError: false,
      userIdErrorText: '',
      userPassErrorText: '',
    };
  },
  created() {
     const rememberMeValue = LocalStorageService.getLocalStorageItemByKey("RememberMe");
     this.rememberMe = rememberMeValue == 'true' ? true:false;
  },
  computed: {
    ...mapGetters(['loginMessagebox', 'rememberMeStore']),
  },
  methods: {
    ...mapActions(['login', 'setRememberMe']),
    setRememberMeValue(event) {
      if (this.rememberMe) {
        this.setRememberMe(true);
      } else {
        this.setRememberMe(false);
      }
    },
    tryLogin() {
      if (this.validate()) {
        const rememberMe = LocalStorageService.getLocalStorageItemByKey("RememberMe");
        this.login({ UserName: this.userId.toLowerCase(), UserPassword: this.password , RememberMe: rememberMe});
      }
    },
    validate() {
      let isError = false;
      this.resetValidation();

      if (this.userId === '') {
        this.userIdErrorText = emptyFieldText;
        this.userIdError = true;
        isError = true;
      }

      if (this.password === '') {
        this.userPassErrorText = emptyFieldText;
        this.passwordError = true;
        isError = true;
      }

      return isError ? false : true;
    },
    resetValidation() {
      this.userIdError = false;
      this.passwordError = false;
      this.userIdErrorText = '';
      this.userPassErrorText = '';
    },
  },
};
</script>

<style scoped>
.login-component {
  color: #727272;

  &__message-box {
    @apply text-red-600 text-small-chat font-kohinoor-light;
  }

  &__fields {
    @apply flex mx-auto;
    flex-direction: column;
    max-width: 420px;

    input {
      @apply flex w-full text-black my-3 pl-4 border-b;
      border-color: #eaeaea;
      height: 35px;

      &.error {
        border-bottom: 1px solid #e53e3e;
      }
    }

    .input-error {
      @apply text-red-600 text-small-chat font-kohinoor-light;
    }
  }

  &__remember {
    @apply flex mx-auto my-4 items-center text-xl;
    max-width: 420px;

    input[type='checkbox'] {
      @apply mt-0 mr-4 appearance-none p-3 bg-white;
      width: 15px;
      border: 1px solid #dcddde;
      transform: matrix(-1, 0, 0, -1, 0, 0);

      &:checked {
        background-color: #007d57;
        background-size: 9px 9px;
      }
    }
  }

  &__controls {
    @apply flex w-full mx-auto items-center pt-8;
    max-width: 420px;

    button {
      @apply h-16 w-full bg-green-chat-lighter rounded text-white uppercase font-kohinoor-bold text-center;
      transition: 0.5s;

      &:hover {
        @apply bg-black;
      }
    }
  }
  &__forgot-password {
    @apply w-full flex mx-auto mt-6 text-mid-chat text-left;
    max-width: 420px;

    p {
      @apply text-center;
    }
  }
}
</style>

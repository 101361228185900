const tokenName = 'mdaChatToken';
export default {
    getLocalStorageItemByKey(key) {
        return localStorage.getItem(key);
    },
    getLocalStorageItem() {
        return localStorage.getItem(tokenName) ? JSON.parse(localStorage.getItem(tokenName)) : null;
    },

    setLocalStorageDetails(localStorageDetails) {
        localStorage.setItem(tokenName, JSON.stringify(localStorageDetails));
    },

    setLocalStorageItem(key, value) {
        localStorage.setItem(key, value);
    },

    removeLocalSorageItem() {
        localStorage.removeItem(tokenName);
    }
}
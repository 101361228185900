import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/components/login/login';
import Activity from '@/components/activities/Activity';
import Chats from '@/components/chats/Chats';
import Archive from '@/components/archive/Archive';
import Advisors from '@/components/advisors/Advisors';
import Profile from '@/components/profile/Profile';
import config from './../../vue.config';

import LocalStorageService from '../services/LocalStorageService';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: config.publicPath,
    routes: [
        {
            path: '/',
            name: 'Main',
            component: Activity,
            props: true
        },
        {
            path: '/chats/:chatId?',
            name: 'Chats',
            component: Chats,
            props: true
        },
        {
            path: '/archive',
            name: 'Archive',
            component: Archive,
            props: true
        },
        {
            path: '/advisors',
            name: 'Advisors',
            component: Advisors,
            props: true
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile,
            props: true
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            props: true,
        }
    ],
})

router.beforeEach((to, from, next) => {
    const localStorageItem = LocalStorageService.getLocalStorageItem()
    if (to.name !== 'Login' && localStorageItem === null) {
        next({ name: 'Login' });
    } else {
        next();
    }
});

export default router;
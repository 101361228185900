<template>
  <div class="forgot-password-component">
    <div class="component__title">
        Enter your User ID
    </div>
    <div class="component__fields">
      <input type="text" placeholder="Enter your user ID" />
    </div>
    <div class="component__controls">
      <button type="submit">Retrieve my Password</button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.forgot-password-component {
  .component {
    &__title {
        @apply text-black text-mid-chat my-4;
    }

    &__fields {
      @apply flex mx-auto flex-col;
      max-width: 420px;

      input {
        @apply flex w-full text-black my-3 pl-4 border-b;
        border-color: #eaeaea;
        height: 35px;
      }
    }

    &__controls {
      @apply flex w-full mx-auto items-center pt-8;
      max-width: 420px;

      button {
        @apply h-16 w-full bg-green-chat-lighter rounded text-white font-kohinoor-bold text-center;
        transition: 0.5s;

        &:hover {
          @apply bg-black;
        }
      }
    }
  }
}
</style>
<template>
  <div class="notification-counter">
    <p class="notification-counter__number" v-bind:class="{ double: counterValue.toString().length > 1 }">
      {{ counterValue }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["counterValue"],
};
</script>

<style lang="scss" scoped>
.notification-counter {
  @apply relative rounded-full bg-red-alert text-white text-2xl pl-0;
  height: 24px;
  width: 24px;
  font-size: 15px;
  top: 8px;
  right: 15px;
  margin-right: -24px;

  &__number {
    padding-left: 8px;

    &.double {
      padding-left: 5px;
    }
  }
}
</style>
<template>
  <div class="chats-request-modal">
    <div
      class="chats-request-modal__title"
      slot="title"
    >You have received a transfer request from {{ data.sender.UserDisplayName }}</div>
    <div class="chats-request-modal__body" slot="body">{{ data.message.Message }}</div>
    <div class="chats-request-modal__buttons" slot="buttons">
      <button class="btn-accept" @click="showMessageModal('accept')">Accept</button>
      <button class="btn-decline" @click="showMessageModal('decline')">Decline</button>
    </div>
  </div>
</template>
<script>
import ChatMessageModal from "./ChatMessageModal";
import { mapActions } from "vuex";
export default {
  data() {
    return { interacted: false};
  },
  props: ["data"],
  created() {
    var self = this;
    setTimeout(function() {
      if(!self.interacted) {
        let channelId = '';
        if (self.data.user != undefined) {
          channelId = self.data.user.ChannelId;
        } else if (self.data.sender != undefined) {
          channelId = self.data.sender.ChannelId;
        }
        const params = {Message: "Adviser is away from keyboard.", ModalMessageId: channelId};
        self.declineTransfer(params);
        self.$destroy();
        self.$emit('close');
        self.$el.parentNode.parentNode.parentNode.remove();
      }
      
    },30000);
  },
  methods: {
    ...mapActions(["acceptTransfer", "declineTransfer"]),
    showMessageModal(type) {
      this.interacted = true;
         
      const title =
        type === "accept"
          ? `Send a message to ${this.data.sender.UserDisplayName}`
          : `Reason for decline`;

      this.$modal.show(
        ChatMessageModal,
        {
          data: this.data,
          title: title,
          onSend: (message) => {
              let channelId = '';
              if (this.data.user != undefined) {
                channelId = this.data.user.ChannelId;
              } else if (this.data.sender != undefined) {
                channelId = this.data.sender.ChannelId;
              }
              const params = {Message: message, ModalMessageId: channelId};
              type === 'accept' 
              ? this.acceptTransfer(params)
              : this.declineTransfer(params)

          },
          closeModal: () => {
              this.$emit('close');
          }
           
        },
        {
          draggable: true,
          clickToClose: false,
          height: "auto",
          width: "807px"
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.chats-request-modal {
  padding: 40px 75px 40px;

  &__title {
    @apply font-kohinoor-med text-title-chat py-4;
  }

  &__body {
    @apply font-kohinoor-light text-2xl py-8;
  }

  &__buttons {
    @apply py-4;
    button {
      @apply bg-grey-button text-green-chat-lighter uppercase font-kohinoor-bold text-xl py-4 mb-4 w-full mr-8;
      max-width: 139px;

      &.btn-accept {
        @apply bg-green-chat-lighter text-white;

        &:hover {
          @apply bg-grey-button text-green-chat-lighter;
        }
      }

      &.btn-decline {
        @apply bg-grey-button text-green-chat-lighter;

        &:hover {
          @apply bg-green-chat-lighter text-white;
        }
      }
    }
  }
}
</style>
<template>
  <div class="chats-header">
    <div class="chats-header__content">
      <div class="chats-header__title chats-header__title--strong">
        Archived Chats
      </div>
      <div class="chats-header__info-section">
        <div class="chats-header__info-section chats-header__info-section--strong">
          {{ currentArchivedChat.length !== 0 ? currentArchivedChat.ChannelUsers.filter(user => user.UserRoleName.toLowerCase() === 'member')[0].UserDisplayName : '' }}
        </div>
        <div class="chats-header__info-section">
          {{ currentArchivedChat.length !== 0 ? 'Member Number: ' + currentArchivedChat.ChannelUsers.filter(user => user.UserRoleName.toLowerCase() === 'member')[0].MemberNumber : '' }}
        </div>
        <div class="chats-header__info-section">
          {{ currentArchivedChat.length !== 0 ? currentArchivedChat.ChannelUsers.filter(user => user.UserRoleName.toLowerCase() === 'member')[0].MemberSpeciality : '' }}
        </div>
        <div class="chats-header__info-section chats-header__info-section--strong">
          {{ currentArchivedChat.length !== 0 ? 'Ref: ' + currentArchivedChat.Id : '' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['currentArchivedChat']),
  },
  methods: {

  }
};
</script>

<style scoped>
.chats-header {
  @apply border-b-1 border-grey-divider; 

  &__info-section {
    @apply flex w-full font-kohinoor-demi text-center duration-100 text-mid-chat items-center;

    &--strong {
      @apply font-kohinoor-med;
    }
  }

  &__title {
    @apply pt-8 pb-8 pl-20 font-kohinoor-demi text-mid-chat duration-100 w-full text-left;
    max-width: 440px;

    &--strong {
      @apply font-kohinoor-med;
    }
  }

  &__content {
    @apply flex;
  }
}
</style>

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import router from './router/index';
import VueJwtDecode from 'vue-jwt-decode';
import { getActive } from './components/mixins/getActive';
import { mapActions } from 'vuex';
import LocalStorageService from './services/LocalStorageService';
import TabNotificationService from './services/TabNotificationService';

export default {
  mixins: [getActive],
  methods: {
    ...mapActions(['getAdvisorByUserName']),
    IsTokenValid(token) {
      var parsedToken = VueJwtDecode.decode(token);
      if (parsedToken != null && parsedToken.exp != undefined) {
        var expiryDate = new Date(0);
        expiryDate.setUTCSeconds(parsedToken.exp);
        expiryDate = expiryDate.valueOf(); 
      
        return Date.now() < expiryDate;
      }
      return false;
    }
  },
  mounted() {
    this.cleanNotifications();
    const user = LocalStorageService.getLocalStorageItem();
    if (user) {
      this.getAdvisorByUserName(user.UserName);
      this.initialiseAdvisorsServices();
      this.initialiseChatServices();
      this.initialiseActivitiesServices();
    }
    if (user != null && user.token != undefined && this.IsTokenValid(user.token)) {
      if (this.$route.name != 'Main') {
        router.push({ path: '/' });
      }
    }

    TabNotificationService.hookUpNotifications();
  },
};
</script>

<style>
#app {
  @apply h-full;
}
</style>

<template>
  <div
    class="message-wrapper"
    :class="isUserAdvisor(messageProp.UserId) ? 'message-wrapper--right' : ''"
  >
    <avatar
      :fullname="
        messageProp.UserDisplayName ? decodeString(messageProp.UserDisplayName) : 'A'
      "
      :size="44"
      color="#105c46"
      v-if="!isUserAdvisor(messageProp.UserId)"
    ></avatar>
    <div class="message-wrapper__container">
      <div
        v-linkified
        class="message-wrapper__message"
        :class="
          isUserAdvisor(messageProp.UserId)
            ? 'message-wrapper__message--advisor'
            : ''
        "
      >
        {{ decodeString(messageProp.Message) }}
      </div>
      <div
        class="message-wrapper__send-state"
        v-if="isUserAdvisor(messageProp.UserId)"
      >
        <SendReceiveElement
          :sentState="
            messageProp.Read ? 'Read' : messageProp.Delivered ? 'Delivered' : ''
          "
          :time="messageProp.Updated"
        />
      </div>
    </div>
    <avatar
      :image="getAdvisorPicture(messageProp.UserId)"
      :size="44"
      v-if="
        isUserAdvisor(messageProp.UserId) && 
        getAdvisorPicture(messageProp.UserId) !== ''
      "
    ></avatar>
    <avatar
      v-if="
        isUserAdvisor(messageProp.UserId) &&
         getAdvisorPicture(messageProp.UserId) === ''
      "
      :fullname="
        getAdvisorDisplayName(messageProp.UserId)
          ? advisorByName.Advisor.UserDisplayName
          : 'A'
      "
      :size="44"
      color="#105c46"
    ></avatar>
  </div>
</template>

<script>
import Avatar from 'vue-avatar-component';
import SendReceiveElement from './SendReceiveElement';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      url: require('../../assets/images/1_Justin_GettyImages-985138634@3x.jpg'),
    };
  },
  components: {
    //eslint-ignore-next-line
    Avatar,
    SendReceiveElement,
  },
  props: ['messageProp', 'advisor', 'member'],
  methods: {
    decodeString(message) {
      var parser = new DOMParser;
      var dom = parser.parseFromString(
          '<!doctype html><body>' + message,
          'text/html');
      var decodedString = dom.body.textContent;

      return decodedString;
    },
    isUserAdvisor(userId) {
            return this.member.UserId === userId ? false : true;
    },
    getAdvisorPicture(userId) {
      const advisorPicture = this.advisors.find(user => user.UserId.toLowerCase() === userId.toLowerCase());  
      if (advisorPicture) {
        return advisorPicture.PictureUrl;       
      } 
    },
    getAdvisorDisplayName(userId) {
       const advisorName = this.advisors.find(user => user.UserId.toLowerCase() === userId.toLowerCase())[0];
       if (advisorName) {
         return advisorName.UserDisplayName;
       }
    }
  },
  computed: {
    ...mapGetters(['advisorByName', 'advisors']),
  },
};
</script>
<style>
.avatar {
  @apply font-kohinoor-light mt-7 text-title-chat !important;

  &--advisor {
    @apply text-green-chat;
  }
}

.message-wrapper {
  @apply flex;
  max-width: 600px;

  &__container {
    @apply block;
    width: fit-content;
  }

  &__message {
    @apply border border-grey-divider rounded-bubble mt-7 p-7 mx-7 whitespace-pre-line break-words;
    max-width: 500px;
    & a {
        @apply text-black underline break-words;
      }
    &--advisor {
      @apply bg-blue-bubble text-white text-right;
      & a {
        @apply text-white underline break-words;
      }
    }
  }

  &__send-state {
    @apply pl-7 mt-4;
  }

  &--right {
    @apply ml-auto mr-0 pl-0 justify-end;
  }
}
</style>

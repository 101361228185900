import Vue from 'vue';
import Vuex from 'vuex';

import Activities from './modules/Activities';
import Chats from './modules/Chats';
import Archives from './modules/Archives';
import Advisors from './modules/Advisors';
import Profile from './modules/Profile';
import Transfer from './modules/Transfer';
import { actionTypes } from './actionTypes';
import VuexPersist from 'vuex-persist';


Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  supportCircular: true,
  key: 'Vuex', 
  storage: window.localStorage,
  reducer: state => ({
    Activites: state.Activites,
    Advisors: state.Advisors,
    Archives: state.Archives,
    Profile: {
      auth: {
        isRefreshing: state.Profile.auth.isRefreshing,
        refreshingCall: state.Profile.auth.refreshingCall,
        loginMessageBox: ''
      },
      notification: state.Profile.notification,
      advisorByName: state.Profile.advisorByName,
      file: state.Profile.files
    },
    Transfer: {
      transferAdvisor: state.Transfer.transferAdvisor,
      notifications: {
        show: false,
      },
      modals: {
        isRunning: false,
        modalRenders: state.Transfer.modals.modalRenders
      }
    }
  })
})

export const store = new Vuex.Store({
  actions: {
    clear({commit}) {
      commit(actionTypes.CLEAR_ALL_ADVISORS);
      commit(actionTypes.CLEAR_ALL_CHATS);
      commit(actionTypes.CLEAR_PROFILE);
      commit(actionTypes.CLEAR_ALL_ARCHIVES);
      commit(actionTypes.CLEAR_ALL_ACTIVITIES);
      commit(actionTypes.CLEAR_ALL_TRANSFER_STATES);
    }
  },
  modules: {
    Activities,
    Chats,
    Archives,
    Advisors,
    Profile,
    Transfer
    
  },
  plugins: [vuexLocalStorage.plugin]

});

<template>
  <div class="profile-header">
    <div class="profile-header__content">
      <div class="profile-header__section">
        Welcome, {{ advisorByName.Advisor.UserDisplayName }}
      </div>
      <div class="profile-header__section" @click="OpenlogoutModal()">Log out</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LogoutModal from '../logout/LogoutModal';

export default {
  computed: {
    ...mapGetters(['advisorByName'])
  },
  methods: {
    OpenlogoutModal() {
      this.$modal.show(
       LogoutModal,
        {
          draggable: false,
          height: '300px',
          width: '600px'
        }
      );
     
    }
  }
};
</script>

<style scoped>
.profile-header {
  @apply border-b-1 border-grey-divider;

  &__section {
    @apply pt-16 pb-8 font-kohinoor-med text-mid-chat text-center duration-100 ml-17;

    &:last-child {
      @apply text-green-chat-lighter ml-auto cursor-pointer;
      padding-right: 14.7rem;
    }
  }

  &__content {
    @apply flex justify-start;
  }
}
</style>

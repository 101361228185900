<template>
  <div class="profile-edit">
    <div class="profile-edit__container">
      <div class="profile-edit-bio-wrapper">
        <h3 class="profile-edit__title">
        Edit Bio
      </h3>
      <span class="profile-edit__error" v-if="bio === ''" style='padding-top: 10px;'>Required</span>

      </div>
      
      <textarea
        name=""
        type="input"
        class="profile-edit__input"
        placeholder="Type your small bio..."
        v-model="bio"
      > 
      </textarea>
      <div class="profile-edit__text">
        <div class="profile-edit__subtitle">Specialist Advice Areas</div>
        <span class="profile-edit__error" v-if="specialities === ''">Required</span>
      </div>
      <textarea
        name=""
        type="input"
        class="profile-edit__input profile-edit__input--small"
        placeholder="Type your speciality..."
        v-model="specialities"
      ></textarea>
      <div class="profile-edit__button-container">
        <button class="profile-edit__button" @click="editProfile()">
          update
        </button>
        <button class="profile-edit__button" @click.prevent="$emit('close')">
          cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      bio: this.$store.getters.advisorByName.Advisor.Description,
      specialities: this.$store.getters.advisorByName.Advisor.SpecialistAdviceAreas,
    };
  },
  computed: {
    ...mapGetters(['advisorByName']),
  },
  methods: {
    ...mapActions(['editProfileInfo']),
    editProfile() {
      const info = {
        UserId: this.advisorByName.Advisor.UserId,
        Description: this.bio,
        UserDisplayName: this.advisorByName.Advisor.UserDisplayName,
        SpecialistAdviceAreas: this.specialities,
      };
      if (this.bio != '' && this.specialities != '') {
        this.editProfileInfo(info);
        this.$emit('close');
      }
    },
  },
};
</script>

<style scoped>
.profile-edit {
  @apply w-full;
  &-bio-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &__input {
    @apply border border-solid border-grey-divider mt-10 mx-12 rounded-bubble  p-7 resize-none h-full w-full;
    min-height: 145px;

    &:focus {
      @apply outline-none;
    }

    &--small {
      min-height: 56px;
      max-height: 56px;
      @apply leading-none mt-3;
    }
  }

  &__text {
    @apply flex justify-end self-start w-full justify-between;
  }

  &__container {
    @apply flex flex-col items-center;
    padding: 80px 86px;
  }

  &__title {
    @apply self-start font-kohinoor-med text-3xl capitalize;
  }

  &__subtitle {
    @apply font-kohinoor-med text-small-chat text-grey-advisor capitalize self-start pt-9;
  }

  &__error {
    @apply font-kohinoor-med text-small-chat pt-9 text-red-alert;
   
  }

  &__button {
    @apply bg-grey-button text-green-chat-lighter uppercase font-kohinoor-bold text-small-chat py-4 w-full duration-100 mr-4 mt-9;
    max-width: 139px;

    &-container {
      @apply self-start flex w-full;
    }

    &:hover {
      @apply bg-green-chat-lighter text-white;
    }
  }
}
</style>

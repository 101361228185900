import Vue from 'vue';
import { getAllAdvisors } from '../../api/advisors-api/api';
import { actionTypes } from '../actionTypes';
let advisorInterval;

const state = {
  users: [],
  currentUsers: [],
  currentAdvisorFilter: '',
  advisors: []
};

const getters = {
  users: (state) => state.users,
  currentAdvisorFilter: (state) => state.currentAdvisorFilter,
  currentUsers: (state) =>  state.users.filter((user) => user.OnlineStatus === state.currentAdvisorFilter),
  advisors: (state) => state.advisors};

const actions = {
  async initialiseAdvisorsServices({ dispatch }) {
   advisorInterval= setInterval(function () {
      dispatch('getAllAdvisors'); 
      dispatch('getAllUsers');
    }, 5000);
  },
  
  async clearAdvisorServices() {
    clearInterval(advisorInterval);
   },

  async getAllAdvisors({ commit, rootState }, users) {
    let response;

    try {
      response = await getAllAdvisors.getAdvisors(users);
      const profile = rootState.Profile.advisorByName.Advisor.UserId.toLowerCase();
      const filteredAdvisors = response.data.Users.filter(
        (advisor) => advisor.UserId.toLowerCase() !== profile
      );
      commit(actionTypes.GET_ADVISORS, filteredAdvisors);

    } catch (error) {
      Vue.$log.error('Error - getAllAdvisors:', error);
    }
  },

  async getAllUsers({ commit}, users) {
    let response;

    try {
      response = await getAllAdvisors.getAdvisors(users);
    
      commit(actionTypes.GET_USERS, response.data.Users);

    } catch (error) {
      Vue.$log.error('Error - getAllAdvisors:', error);
    }
  },
  setAdvisorStateFilter({commit}, filter) {
    commit(actionTypes.SET_ADVISOR_FILTER, filter);
  },
 
  setActiveAdvisor({ commit }, userId) {
    commit(actionTypes.SET_ACTIVE_Advisor, userId);
  },

  clearAdvisors({ commit }) {
    commit(actionTypes.CLEAR_ALL_ADVISORS);
  },
};

const mutations = {
  CLEAR_ALL_ADVISORS(state) {
    state.users = [];
    state.currentUsers = [];
    state.currentAdvisorFilter = '';
  },
  GET_ADVISORS(state, users) {
    state.users = users;
  },
  GET_USERS(state, advisors) {
    state.advisors = advisors;
  },
  SET_ADVISOR_FILTER(state, filter) {
    state.currentAdvisorFilter = filter;
  },
  
  SET_ACTIVE_ADVISOR(state, userId) {
    state.currentUsers = users.filter((user) => user.UserId === userId);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

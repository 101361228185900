<template>
  <div class="dashboard">
    <dash-side-bar />
    <div class="dashboard__content">
      <BasicTopNotification />
      <activity-header />
      <activity-content />
    </div>
  </div>
</template>

<script>
import DashSideBar from '../DashSidebar';
import ActivityHeader from './ActivityHeader';
import ActivityContent from './ActivityContent';
import BasicTopNotification from '../Basic/BasicTopNotification';

export default {
  components: {
    DashSideBar,
    ActivityHeader,
    ActivityContent,
    BasicTopNotification
  },
};
</script>

<style>
.dashboard {
  @apply flex;
  height: 100vh;

  &__content {
    @apply flex flex-col w-full overflow-y-scroll;
  }
}
</style>

<template>
  <div class="advisors__container">
    <avatar
      v-if="advisor.PictureUrl != ''"
      :image="advisor.PictureUrl"
      :size="138"
      color="#105c46"
    ></avatar>
    <avatar
      v-if="advisor.UserDisplayName && advisor.PictureUrl === ''"
      :fullname="
        advisor.UserDisplayName ? advisor.UserDisplayName : 'A'
      "
      :size="138"
      color="#105c46"
    ></avatar>
    <div class="advisors__content">
      <div class="advisors__title-container">
        <h3 class="advisors__title">{{ advisor.UserDisplayName }}</h3>
        <span class="advisors__city">{{ advisor.City }}</span>
      </div>
      <p v-if="!isTransferMode" class="advisors__text">
        {{ advisor.Description }}
      </p>
      <div class="advisors__subtitle">Specialist Advice Areas</div>
      <div class="advisors__speciality">
        {{ advisor.SpecialistAdviceAreas }}
      </div>
      <button
        v-if="isTransferMode"
        slot="transfer-button"
        class="advisors__button"
        @click="showMessageAdvisor(advisor)"
      >
        transfer chat
      </button>
    </div>
  </div>
</template>

<script>
import Avatar from 'vue-avatar-component';
import ChatMessageModal from './../chats/ChatMessageModal';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      items: [],
      url: require('../../assets/images/2_Layne_GettyImages-985138658@3x.jpg'),
    };
  },
  props: ['advisor', 'isTransferMode', 'channelId'],
  components: {
    Avatar,
  },
  methods: {
    ...mapActions(['setTransferAdvisor', 'requestTransfer']),
    showMessageAdvisor(advisor) {
      this.setTransferAdvisor(advisor);
      this.$modal.show(
        ChatMessageModal,
        {
          title: `Send a message to ${advisor.UserDisplayName}`,
          onSend: (message) => {
            this.requestTransfer({ message, channelId: this.channelId });
            this.$modal.hide('chats-transfer');
          },
          closeModal: () => {},
        },
        {
          name: 'chat-message-modal',
          draggable: true,
          height: 'auto',
          scrollable: true,
          width: '807px',
        }
      );
    },
  },
};
</script>

<style scoped>
.advisors {
  &__content {
    @apply w-full ml-24;
  }

  &:only-child {
    & > .advisors__container {
      @apply border-b-2 border-solid  border-grey-divider mb-20;
    }
  }
  &:last-child {
    .chats-transfer__container & {
      & > .advisors__container {
        @apply border-none mb-6;
      }
    }
  }

  &:last-child {
    @apply border-b-2 border-solid border-grey-divider mb-20;
  }

  & > .advisors__container {
    @apply border-b-2 border-solid border-grey-divider;
  }

  &__container {
    @apply flex pt-16 pb-13 w-full ml-17 pl-12;
    max-width: 1159px;

    .chats-transfer__container & {
      @apply ml-0 pl-0;
    }
  }

  &__title {
    @apply text-4xl font-kohinoor-demi capitalize pb-2;

    &-container {
      @apply flex items-center;
    }
  }

  &__text {
    @apply pt-8 pb-6 font-kohinoor-demi text-2xl break-words;
  }

  &__subtitle {
    @apply font-kohinoor-med text-small-chat text-grey-advisor capitalize;
  }

  &__city {
    @apply uppercase font-kohinoor-bold text-green-chat-lighter text-small-chat ml-auto;
  }

  &__button {
    @apply bg-grey-button text-green-chat-lighter uppercase font-kohinoor-bold text-small-chat py-4 mt-8 w-full duration-100;
    max-width: 139px;

    &:hover {
      @apply bg-green-chat-lighter text-white;
    }
  }

  &__speciality {
    @apply font-kohinoor-demi text-2xl;
  }
}

.avatar {
  @apply flex-shrink-0;
}
</style>

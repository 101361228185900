import { store } from '../store/store';
import { constants } from '../constants';

const staticMessage = ' Please try again or contact your administrator';
const REMOVE_NOTIFICATION = { show: false, isError: false, message: '', id: 'REMOVE_NOTIFICATION'};
const TRANSFER_REQUEST_SENT = { show: true, isError: false, message: 'Transfer request sent!'};
const ERROR_TRANSFER_REQUEST = { show: true, isError: true, message: 'There was an error processing your transfer request.' + staticMessage};
const ERROR_SENDING_MESSAGE = { show: true, isError: true, message: 'There was an error sending your message.' + staticMessage};
const ERROR_RETRIEVING_CHATS = { show: true, isError: true, message: 'Error: Attempting to retrieve active channels has failed.' + staticMessage};
const ERROR_RETRIEVING_ACTIVITIES = { show: true, isError: true, message: 'Error: Attempting to retrieve current activities has failed.' + staticMessage};
const ERROR_RETRIEVING_ADVISORS = { show: true, isError: true, message: 'Error: Attempting to retrieve active advisers has failed.' + staticMessage};
const SERVER_ERROR = { show: true, isError: true, message: 'Connection has been lost. Attempting to reconnect. If connection is not reestablished please contact your administrator', id:'SERVER_ERROR' };
const EMAIL_TRANSCRIPT_SUCCESS = { show: true, isError: false, message: 'Email Transcript has been sent!' };
const EMAIL_TRANSCRIPT_ERROR = { show: true, isError: true, message: 'There was an error when attempting to send the email transcript. ' + staticMessage };

const clearErrorNotification = () => {
    store.dispatch('clearServerErrorNotification', REMOVE_NOTIFICATION);
};
const sendNotification = (notificationType) => {

    let notificationDetails;

    switch(notificationType) {
        case constants.REMOVE_NOTIFICATION:
            notificationDetails = REMOVE_NOTIFICATION;
            break;
        case constants.TRANSFER_REQUEST_SENT:
            notificationDetails = TRANSFER_REQUEST_SENT;
            break;
        case constants.ERROR_TRANSFER_REQUEST:
            notificationDetails = ERROR_TRANSFER_REQUEST
            break;
        case constants.ERROR_SENDING_MESSAGE:
            notificationDetails = ERROR_SENDING_MESSAGE;
            break;
        case constants.ERROR_RETRIEVING_CHATS:
            notificationDetails = ERROR_RETRIEVING_CHATS;
            break;
        case constants.ERROR_RETRIEVING_ACTIVITIES:
            notificationDetails = ERROR_RETRIEVING_ACTIVITIES;
            break;
        case constants.ERROR_RETRIEVING_ADVISORS:
            notificationDetails = ERROR_RETRIEVING_ADVISORS;
            break;
        case constants.SERVER_ERROR:
            notificationDetails = SERVER_ERROR;
            break;
        case constants.EMAIL_TRANSCRIPT_SUCCESS:
            notificationDetails = EMAIL_TRANSCRIPT_SUCCESS;
            break;
        case constants.EMAIL_TRANSCRIPT_ERROR:
            notificationDetails = EMAIL_TRANSCRIPT_ERROR;
            break;
        default:
            notificationDetails = undefined;
    }
    store.dispatch('setNotification', notificationDetails);
}

export default {
    sendNotification,
    clearErrorNotification
}
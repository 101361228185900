<template>
  <div class="modal-component">
    <div class="modal-component__title">{{ title }}</div>
    <div class="modal-component__body">{{ message }}</div>
    <div class="modal-component__buttons">
      <button class="confirm-button" @click="handleClickEvent">Close</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["data", "handleClick"],
  computed: {
    title() {
      switch (this.data.type) {
        case "Accepted":
          return 'Your transfer request has been accepted by ' + this.data.user.UserDisplayName;
        case "Rejected":
          return 'Your transfer request has been rejected by ' +this.data.user.UserDisplayName;
        default:
          return "";
      }
    },
    message() {  
        return this.data.user.Message;
    }
  },
  methods: {
    handleClickEvent() {
      this.handleClick();
      this.$emit("close");
    }
  }
};
</script>
<style scoped>
.modal-component {
  padding: 40px 76px;

  &__title {
    @apply font-kohinoor-med text-title-chat py-4 mb-4;
  }

  &__body {
      @apply font-kohinoor-med text-2xl py-4 my-6;
  }

  &__buttons {
    @apply py-4;

    button {
      @apply bg-green-chat-lighter text-white uppercase font-kohinoor-bold text-xl py-4 mb-4 w-full;
      max-width: 139px;
    }
  }
}
</style>
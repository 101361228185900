import Vue from 'vue';
import router from './../../router/index';
import api from '../../api/chat-api/api';
import { actionTypes } from '../actionTypes';
import ChatNeMessageService from '../../services/ChatNewMessageService';
import TabNotificationService from '../../services/TabNotificationService';
let chatInterval;

const state = {
  chats: [],
  messages: [],
  currentChatId: '',
  files: [],
  transactionGroupId: '',
  attachements: [],
  progressBarInstances: [],
  notifications: {
    notificationCounter: []
  },
  chatMessagesContainer: {},
  lastVisibleChannels: null
};

const getters = {
  chats: (state) => state.chats,
  messages: state => state.messages,
  currentChat: (state) => state.currentChatId !== '' ? state.chats[state.chats.findIndex(c => c.Id === state.currentChatId)] : undefined,
  transactionGroupId: state => state.transactionGroupId,
  attachements: state => state.attachements,
  progressBarInstances: state => state.progressBarInstances,
  chatMessagesContainer: state => state.chatMessagesContainer,
  lastVisibleChannels: state => state.lastVisibleChannels
};

const actions = {

  async initialiseChatServices({ dispatch }) {
      chatInterval = setInterval(function() {
      dispatch('getActivesChats');
      dispatch('getModalContent');

    }, 5000);
  },
   
  async clearChatServices() {
    clearInterval(chatInterval);
   },

  async getActivesChats({ state, rootState, dispatch, commit }) {
    let response;

    try {
      response = await api.activeChats.getUpdates({
        UserId: rootState.Profile.advisorByName.Advisor.UserId,
        UserRole: rootState.Profile.advisorByName.Advisor.UserRole,
      });

      dispatch('processPersonalTransfers', response.data);
      dispatch('processAcceptances', response.data);
      dispatch('processDeclines', response.data);
     
      if (response.data.length !== 0 && ChatNeMessageService.isNewMessageAdded(state.messages.length, response.data[0].ChatMessages.length)) {
        setTimeout(function(){
          state.chatMessagesContainer.scrollTop = state.chatMessagesContainer.scrollHeight;
        },200);
      }

      // Remove any channels that we're not active in (e.g. we could be in the middle of a transfer request)
      const filteredChannels = response.data.filter(c => c.ChannelUsers.find(u => 
        u.UserId.toLowerCase() === rootState.Profile.advisorByName.Advisor.UserId.toLowerCase() && u.UserStatus !== 'Transfer')
      );

      // If the channel has disappeared, remove it from the currently selected chat
      if(filteredChannels.length > 0 && filteredChannels.findIndex(c => c.Id === state.currentChatId) === -1) {
        commit(actionTypes.SET_ACTIVE_CHAT, '');
      }

      // -- Check for notifications
      dispatch('processNotifications', filteredChannels).then(finalChatArray => {
        // Commit new chat data
        commit(actionTypes.SET_CHATS, finalChatArray);
      });

    } catch (error) {
      Vue.$log.error('Error - getActivesChats:', error);
    }
  },
  processMessagesNotifications({ state, commit }, filteredChannels) {
    var isTabHidden = document.hidden;
    var lastSeenMemberMessagesCount = 0;
    
    var updatedMemberMessagesCount = 0;
    if (state.lastVisibleChannels != null) {
      lastSeenMemberMessagesCount = TabNotificationService.getCountOfMemberMessages(state.lastVisibleChannels);
      updatedMemberMessagesCount = TabNotificationService.getCountOfMemberMessages(filteredChannels);
    }

    if (isTabHidden && state.lastVisibleChannels != null) {
      if (updatedMemberMessagesCount > lastSeenMemberMessagesCount) {
        TabNotificationService.setNotification(updatedMemberMessagesCount - lastSeenMemberMessagesCount, "message");
      }
    } else if ( router.history.current.name != 'Chats'  && state.lastVisibleChannels != null) {
      if (updatedMemberMessagesCount > lastSeenMemberMessagesCount) {
        TabNotificationService.setNotification(updatedMemberMessagesCount - lastSeenMemberMessagesCount, "message");
        TabNotificationService.setTitle();
      }
    } else {
      state.lastVisibleChannels = filteredChannels;
      TabNotificationService.setTitle();
      TabNotificationService.removeNotifications('message');
    }
  },
  processNotifications({ state, commit, dispatch }, filteredChannels) {
    dispatch('processMessagesNotifications', filteredChannels);
    filteredChannels.forEach((channel,index) => {
      const channelDoesntExistInNotifications = state.notifications.notificationCounter.findIndex(n => n.channelId === channel.Id) === -1;
      if (channelDoesntExistInNotifications) {
        if (channel.ChatMessages.length !== 0 && state.currentChatId != channel.Id) {
          const lastMessageIndex = channel.ChatMessages.length -1;
          commit(actionTypes.SET_CHAT_NOTIFICATION_WATCHER, { channelId: channel.Id, index: lastMessageIndex });
          filteredChannels[index].NotificationCount = 1;
        } else {
          commit(actionTypes.SET_CHAT_NOTIFICATION_WATCHER, { channelId: channel.Id, index: 0 });
          filteredChannels[index].NotificationCount = 0;
        }
      } else {
        const lastMessageIndex = channel.ChatMessages.length - 1;
        if (state.currentChatId != channel.Id) {
          const notificationIndex = state.notifications.notificationCounter.find(n => n.channelId === channel.Id).index;
          filteredChannels[index].NotificationCount = lastMessageIndex - notificationIndex;
        } else {
          commit(actionTypes.SET_CHAT_NOTIFICATION_WATCHER, { channelId: channel.Id, index: lastMessageIndex });
          filteredChannels[index].NotificationCount = 0;
        }
        
      }
    });


    return filteredChannels;
  },

  setMessagesContainer({commit}, container) {
    commit(actionTypes.SET_MESSAGES_CONTAINER, container);
  },

  async endChannel({ state, rootState, dispatch }) {
    let response;
    const params = {
      ChannelId: state.currentChatId,
      UserId: rootState.Profile.advisorByName.Advisor.UserId,
      UserDisplayName: rootState.Profile.advisorByName.Advisor.UserDisplayName,
    };

    try {
      response = await api.transfers.endChannel(params);
      dispatch('removeClosedChannel');
      dispatch('resetModalRunningState', false);
      dispatch('removeProgressBarInstance');
    } catch (error) {
      Vue.$log.error('Error - endChannel: ', error);
      dispatch('resetModalRunningState', true);
    }
  },

  async exitChannel({ rootState, dispatch }, channelId) {
    let response;
    const params = {
      ChannelId: channelId,
      UserId: rootState.Profile.advisorByName.Advisor.UserId,
    };

    try {
      response = await api.transfers.exitChannel(params);
      dispatch('removeClosedChannel');
      dispatch('resetModalRunningState', false);
    } catch (error) {
      Vue.$log.error('Error - exitChannel: ', error);
      dispatch('resetModalRunningState', true);
    }
  },

  removeClosedChannel({ state, commit }) {
    const i = state.chats.indexOf(
      state.chats.find((c) => c.Id === state.currentChatId)
    );

    if(i !== -1) {
      const newChatArray = state.chats.filter((c) => c.Id !== state.currentChatId);
      commit(actionTypes.SET_CHATS, newChatArray);
      commit(actionTypes.REMOVE_CHAT_NOTIFICATION_WATCHER, state.currentChatId);
      commit(actionTypes.SET_ACTIVE_CHAT, '');
    }
  },

  setActiveChat({ commit }, chatDetails) {
    commit(actionTypes.SET_ACTIVE_CHAT, chatDetails.channelId);
    commit(actionTypes.SET_CHAT_NOTIFICATION_WATCHER, chatDetails);
  },

  pushProgressBarInstance({ commit }, progressBarInstance) {
    commit(actionTypes.PUSH_PROGRESS_BAR_INSTANCE, progressBarInstance);
  },

  removeProgressBarInstance({commit}) {
    commit(actionTypes.REMOVE_PROGRESS_BAR_INSTANCES);
  },

  async sendMessage({ state, commit }, message) {
    let response;
    try {
      response = await api.sendMessage.addMessage(message);
      if (message.TransactionGroupId != '' && message.TransactionGroupId != undefined) {
        commit(actionTypes.MARK_SENT_ATTACHEMENT, message.TransactionGroupId);
      }
      commit(actionTypes.SEND_MESSAGE, message);
      const watcherIndex = state.notifications.notificationCounter.find(w => w.channelId === message.ChannelId).index + 1;
      commit(actionTypes.SET_CHAT_NOTIFICATION_WATCHER, { channelId: message.ChannelId, index: watcherIndex });
      return true;
    } catch (error) {
      Vue.$log.error('Error - sendMessage: ', error);
      return false;
    }
  },

  submitFile({ commit }, file) {
    api.uploadFile
      .addFile(file)
      .then(function(res) {
        file.instance.$props.transactionId = res.data.TransactionGroupId;
        commit(actionTypes.PUSH_ATTACHEMENT, res.data.TransactionGroupId);
        file.instance.$props.isLoaded = true;
        commit(actionTypes.ADD_FILE, file);
      })
      .catch(function(error) {
        Vue.$log.error('Error submitFile:', error);
        file.instance.$props.isLoaded = true;
        file.instance.$props.errors = error.response.data[0];
      });
  },
  removeFile({ commit }, file) {
    api.cancelUpload
      .cancelFile(file)
      .then(function() {
        commit(actionTypes.REMOVE_FILE, file);
      })
      .catch(function(error) {
        Vue.$log.error('Error - removeFile:', error);
      });
  },

  clearChats({ commit }) {
    commit(actionTypes.CLEAR_ALL_CHATS);
  },
};

const mutations = {
  SET_CHATS(state, chats) {
    state.chats = chats;
  },

  SET_ACTIVE_CHAT(state, chatId) {
    state.currentChatId = chatId;
  },

  SET_CHAT_NOTIFICATION_WATCHER(state, chatDetails) {
    const idx = state.notifications.notificationCounter.findIndex(n => n.channelId === chatDetails.channelId);
    if (idx === -1) {
      state.notifications.notificationCounter.push(chatDetails);
    }
    else {
      state.notifications.notificationCounter[idx] = chatDetails;
    }
  },

  REMOVE_CHAT_NOTIFICATION_WATCHER(state, channelId) {
    const newArr = state.notifications.notificationCounter.filter(n => n.channelId !== channelId);
    state.notifications.notificationCounter = newArr;
  },

  SEND_MESSAGE(state, message) {
    const idx = state.chats.findIndex(x => x.Id === message.ChannelId);
    state.chats[idx].ChatMessages = [...state.chats[idx].ChatMessages, message];
  },

  ADD_FILE(state, file) {
    state.files.push(file);
    state.transactionGroupId = file.instance.transactionId;
  },

  REMOVE_FILE(state, file) {
    state.files.splice(state.files.indexOf(file), 1);
  },

  REMOVE_PROGRESS_BAR_INSTANCES(state) {
    state.progressBarInstances.filter(
      (x) => x.$props.isVisible = false
    )
  },

  PUSH_PROGRESS_BAR_INSTANCE(state, instance) {
    state.progressBarInstances.push(instance);
  },

  PUSH_ATTACHEMENT(state, transactionGroupId) {
    state.attachements.push({
      chatId: state.currentChatId,
      transactionId: transactionGroupId,
      isSent: false,
    });
  },

  MARK_SENT_ATTACHEMENT(state, transactionGroupId) {
    state.attachements.filter(
      (x) => x.transactionId == transactionGroupId
    )[0].isSent = true;
    state.progressBarInstances.filter(
      (x) => x.$props.transactionId == transactionGroupId
    )[0].$props.isSent = true;
  },

  SET_MESSAGES_CONTAINER(state, container) {
    state.chatMessagesContainer = container;
  },

  CLEAR_ALL_CHATS(state) {
    state.chats = [];
    state.messages = [];
    state.currentChatId = '';
    state.files = [];
    state.transactionGroupId = '';
    state.attachements = [];
    state.progressBarInstances = [];
    state.notifications.notificationCounter = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import Vue from 'vue';
import api from '../../api/chat-api/api';
import { actionTypes } from '../actionTypes';
import BasicModal from '../../components/Basic/BasicModal';
import ChatsRequestModal from '../../components/chats/ChatsRequestModal';
import BasicConditionalModal from '../../components/Basic/BasicConditionalModal';
import ChatsTransfer from '../../components/chats/ChatsTransfer';
import moment from 'moment';
import TabNotificationService from '../../services/TabNotificationService';

const state = {
  transferAdvisor: {},
  modals: {
    isRunning: false,
    modalRenders: []
  }
};

const getters = {
  transferAdvisor: state => state.transferAdvisor,
  modalRenders: state => state.modals.modalRenders
}

const actions = {
  setTransferAdvisor({ commit }, advisor) {
    commit(actionTypes.SET_TRANSFER_ADVISOR, advisor);
  },

  getModalContent({ state, dispatch, commit }) {

    if (state.modals.modalRenders.length > 0 && state.modals.isRunning !== true) {
      const nextModal = state.modals.modalRenders[0];

      commit(actionTypes.SET_MODAL_RUNNING_STATE, true);
      dispatch('displayModal', nextModal);
    }
  },

  displayModal({ dispatch }, modalInfo) {
    if (modalInfo.type === 'Request') {
      this._vm.$modal.show(
        ChatsRequestModal,
        {
          data: modalInfo,
        },
        {
          dragable: false,
          clickToClose: false,
          width: '40%',
          height: 'auto'
        }
      );
    }

    if (modalInfo.type === 'Accepted') {
      this._vm.$modal.show(
        BasicModal,
        {
          data: modalInfo,
          handleClick: () => {
            dispatch('removeConfirmModal', modalInfo.user.ChannelId); 
            dispatch('exitChannel', modalInfo.user.ChannelId, modalInfo.user.UserId );
          }
        },
        {
          dragable: false,
          clickToClose: false,
          width: '40%',
          height: 'auto'
        }
      );
    }

    if (modalInfo.type === 'Rejected') {
      this._vm.$modal.show(
        BasicConditionalModal,
        {
          data: modalInfo,
          title: 'Your transfer request has been rejected by ' + modalInfo.user.UserDisplayName,
          message: 'Would you like to transfer to another advisor?',
          channelId: modalInfo.user.ChannelId,
          handleClick: () => {
            dispatch('removeConfirmModal', modalInfo.user.ChannelId); 
            this._vm.$modal.show(
              ChatsTransfer,
              { 
                idToHide: modalInfo.user.UserId,
                channelId: modalInfo.user.ChannelId
              },
              {
                name: 'chats-transfer',
                draggable: false,
                height: 'auto',
                width: '935px',
                scrollable: true
              }
            );
          }
        },
        {
          dragable: false,
          clickToClose: false,
          width: '40%',
          height: 'auto',
          scrollable: true
        }
      );
    }
  },

  // Look for personal transfers
  processPersonalTransfers({ commit, state, rootState }, data) {
    const myUserId = rootState.Profile.advisorByName.Advisor.UserId;
    const personalTransferRequests = data.filter(c => c.ChannelUsers.find(u => u.UserId.toLowerCase() === myUserId.toLowerCase() && u.UserStatus === 'Transfer'));
    if (personalTransferRequests.length > 0) {
      for (let i = 0; i < personalTransferRequests.length; i++) {
        const sender = personalTransferRequests[i].ChannelUsers.find(user => user.UserId.toLowerCase() !== myUserId.toLowerCase() && user.UserRoleName === 'Advisor');
        const message = personalTransferRequests[i].ChatMessages.reverse().find(message => message.Command = 'RequestTransfer');

        let existInModalRenders = false;
        for (let y = 0; y < state.modals.modalRenders.length; y++) {
          if (state.modals.modalRenders[y].type == 'Request') {
            if (state.modals.modalRenders[y].sender.ChannelId === message.ChannelId) {
              existInModalRenders = true;
              break;
            }
          }
          else if (state.modals.modalRenders[y].type == 'Accepted' || state.modals.modalRenders[y].type == 'Rejected' ) {
            if (state.modals.modalRenders[y].user.ChannelId === message.ChannelId) {
              existInModalRenders = true;
              break;
            }
          }

        }



        if (message && !existInModalRenders) {
          TabNotificationService.setNotification(1,'transfer');
          commit(actionTypes.SET_MODAL_CONTEXT, { type: 'Request', sender, message });
        }
      }
    }
  },

  // Look for third-party transfers
  processDeclines({ commit, state }, data) {
    data.forEach(c => {
      const filteredDeclines = c.ChatMessages.filter(m => m.Command === 'RequestRejected');
      if (filteredDeclines.length > 0) {
        filteredDeclines.forEach(d => {
          const seconds = moment.utc().diff(moment.utc(d.Created), 'seconds');
          if (seconds < 10 && state.modals.modalRenders.filter(u => u.user.Id.toLowerCase() === d.Id.toLowerCase()).length === 0) {
            commit(actionTypes.SET_MODAL_CONTEXT, { type: 'Rejected', user: d });
          }
        });
      }
    });
  },

  processAcceptances({ commit, state, rootState }, data) {
    const myUserId = rootState.Profile.advisorByName.Advisor.UserId;
    data.forEach((channel) => {
      const requests = channel.ChannelUsers.filter(user => user.UserId.toLowerCase() !== myUserId.toLowerCase() && user.UserStatus === 'Accepted');

      requests.forEach(user => {
        if(state.modals.modalRenders.filter(u => u.user.Id.toLowerCase() === user.Id.toLowerCase()).length === 0) {
          const message = channel.ChatMessages.find(message => message.UserId.toLowerCase() === user.UserId.toLowerCase() && message.Command === 'RequestAccepted');
          const userObj = { ...user, Message: message.Message };
          commit(actionTypes.SET_MODAL_CONTEXT, { type: 'Accepted', user: userObj });
        }
      });
    })
  },
  removeModal({commit}, channelId) {
    commit(actionTypes.REMOVE_MODAL, channelId);
  },
  removeConfirmModal({commit}, channelId) {
    commit(actionTypes.REMOVE_CONFIRM_MODAL, channelId);
  },
  resetModalRunningState({ commit }, isError) {
    if (!isError) {
      commit(actionTypes.REMOVE_MODAL_CONTEXT);
    }
    commit(actionTypes.SET_MODAL_RUNNING_STATE, false);
  },

  async requestTransfer({ state, dispatch }, messageObj) {
    let response;
    const params = {
      ChannelId: messageObj.channelId,
      UserId: state.transferAdvisor.UserId,
      UserDisplayName: state.transferAdvisor.UserDisplayName,
      MemberCategory: state.transferAdvisor.MemberCategory,
      Message: messageObj.message != "" ? messageObj.message : "No transfer message provided"
    }

    try {
      response = await api.transfers.requestTransfer(params);

      if(state.modals.isRunning) {
        dispatch('resetModalRunningState', false);
      }
    } catch (error) {
      Vue.$log.error('Error - requestTransfer: ', error);

      dispatch('resetModalRunningState', true);
    }
  },

  async acceptTransfer({ state, rootState, dispatch }, data) {
    
    const params = {
      ChannelId: state.modals.modalRenders[0].message.ChannelId,
      UserId: rootState.Profile.advisorByName.Advisor.UserId,
      UserDisplayName: rootState.Profile.advisorByName.Advisor.UserDisplayName,
      MemberCategory: rootState.Profile.advisorByName.Advisor.MemberCategory,
      Message: data.Message != "" ? data.Message : "No transfer message provided"
    }
    let response;

    try {
      dispatch('removeModal', data.ModalMessageId); 
      response = await api.transfers.acceptTransfer(params);
      dispatch('getActivesChats');
      dispatch('resetModalRunningState', false);
      TabNotificationService.removeNotifications('transfer');
    } catch (error) {
      Vue.$log.error('Error - acceptTransfer: ', error);

      dispatch('resetModalRunningState', true);
    }

  },

  async declineTransfer({ state, rootState, dispatch }, data) {
    let response;
    const params = {
      ChannelId: state.modals.modalRenders[0].message.ChannelId,
      UserId: rootState.Profile.advisorByName.Advisor.UserId,
      UserDisplayName: rootState.Profile.advisorByName.Advisor.UserDisplayName,
      MemberCategory: rootState.Profile.advisorByName.Advisor.MemberCategory,
      Message: data.Message != "" ? data.Message : "No transfer message provided"
    }

    try {
      dispatch('removeModal', data.ModalMessageId); 
      response = await api.transfers.rejectTransfer(params);
      dispatch('resetModalRunningState', false);
      TabNotificationService.removeNotifications('transfer');
    } catch (error) {
      Vue.$log.error('Error - declineTransfer: ', error);
      dispatch('resetModalRunningState', true);
    }
  },
  clearTransferState({commit}) {
    commit(actionTypes.CLEAR_ALL_TRANSFER_STATES);
  }
}

const mutations = {
  SET_MODAL_CONTEXT(state, messageObj) {
    state.modals.modalRenders.push(messageObj);
  },
  REMOVE_CONFIRM_MODAL( state, channelId) {
    let modalToRemove = null;
    let reducedModals = [];
    for (let y = 0; y < state.modals.modalRenders.length; y++) {
      if (state.modals.modalRenders[y].type == 'Request') {
        if (state.modals.modalRenders[y].sender.ChannelId != channelId) {
          reducedModals.push(state.modals.modalRenders[y]);
        } else {
          modalToRemove = state.modals.modalRenders[y];
        }
      }
      else if (state.modals.modalRenders[y].type == 'Accepted' || state.modals.modalRenders[y].type == 'Rejected' ) {
        if (state.modals.modalRenders[y].user.ChannelId != channelId) {
          reducedModals.push(state.modals.modalRenders[y]);
        } else {
          modalToRemove = state.modals.modalRenders[y];
        }
      }

    }
    //const modalToRemove = state.modals.modalRenders.filter(modal => modal.user.ChannelId === channelId);
    if (modalToRemove) {
      const [, ...rest] = reducedModals;
      state.modals.modalRenders = rest;
    }
  },

  REMOVE_MODAL(state, channelId) {
    //const modalToRemove = state.modals.modalRenders.filter(modal => modal.user.ChannelId === channelId);
    let modalToRemove = null;
    let reducedModals = [];
    for (let y = 0; y < state.modals.modalRenders.length; y++) {
      if (state.modals.modalRenders[y].type == 'Request') {
        if (state.modals.modalRenders[y].sender.ChannelId != channelId) {
          reducedModals.push(state.modals.modalRenders[y]);
        } else {
          modalToRemove = state.modals.modalRenders[y];
        }
      }
      else if (state.modals.modalRenders[y].type == 'Accepted' || state.modals.modalRenders[y].type == 'Rejected' ) {
        if (state.modals.modalRenders[y].user.ChannelId != channelId) {
          reducedModals.push(state.modals.modalRenders[y]);
        } else {
          modalToRemove = state.modals.modalRenders[y];
        }
      }

    }
    if (modalToRemove) {
      const [, ...rest] = reducedModals;
      state.modals.modalRenders = rest;
    }
  },
  REMOVE_MODAL_CONTEXT(state) {
    const [, ...rest] = state.modals.modalRenders;
    state.modals.modalRenders = rest;
  },

  SET_MODAL_RUNNING_STATE(state, bool) {
    state.modals.isRunning = bool;
  },
  SET_TRANSFER_ADVISOR(state, advisor) {
    state.transferAdvisor = advisor;
  },
  CLEAR_ALL_TRANSFER_STATES(state) {
    state.transferAdvisor = {},
    state.modals = {
      isRunning: false,
      modalRenders: []
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations
};
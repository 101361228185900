<template>
  <div class="activity-join">
    <h3 class="activity-join__text">
      Would you like to start chatting to this member?
    </h3>
    <div class="activity-join__button-container">
      <button class="activity-join__button" @click.prevent="joinChannel()">
        yes
      </button>
      <button class="activity-join__button" @click.prevent="$emit('close')">
        no
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['activity'],
  computed: {
    ...mapGetters(['advisorByName'])
  },
  methods: {
    ...mapActions(['joinNewChat']),
    joinChannel() {
      const channelInfo = {
        ChannelId: this.activity.Id,
        UserId: this.advisorByName.Advisor.UserId,
        UserRole: this.advisorByName.Advisor.UserRole,
        UserDisplayName: this.advisorByName.Advisor.UserDisplayName,
        MemberCategory: this.advisorByName.Advisor.MemberCategory
      };
      this.joinNewChat(channelInfo);
    }
  }
};
</script>

<style scoped>
.activity-join {
  @apply flex flex-col items-center align-middle justify-center h-full;

  &__button {
    @apply bg-grey-button text-green-chat-lighter uppercase font-kohinoor-bold text-small-chat py-4 w-full duration-100 mr-4 mt-9;
    max-width: 139px;

    &-container {
      @apply flex w-full align-middle justify-center;
    }

    &:hover {
      @apply bg-green-chat-lighter text-white;
    }
  }
}
</style>

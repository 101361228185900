<template>
  <div class="modal-component">
    <div class="modal-component__title">{{ title }}</div>
    <div class="modal-component__body">{{ messageUser }}</div>
    <div class="modal-component__body">{{ message }}</div>
    <div class="modal-component__buttons">
      <button class="confirm-button" @click="handleClickEvent">Yes</button>
      <button class="cancel-button" @click="handleClose">No</button>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {};
  },
  props: ['title', 'message', 'handleClick', 'channelId', 'data'],
  methods: {
    ...mapActions(['removeConfirmModal']),
    handleClickEvent() {
      this.handleClick();
      this.$emit('close');
    },
    handleClose() {
      this.removeConfirmModal(this.channelId);
      this.$emit('close');
    },
  },
  computed: {
    messageUser() {
      if (this.data) {
        return this.data.user.Message;
      }
    },
  },
};
</script>
<style scoped>
.modal-component {
  padding: 40px 76px;

  &__title {
    @apply font-kohinoor-med text-title-chat py-4 mb-4;
  }

  &__body {
    @apply font-kohinoor-med text-2xl py-4 my-6;
  }

  &__buttons {
    @apply flex justify-between align-middle py-4 w-full;

    button {
      @apply bg-green-chat-lighter text-white uppercase font-kohinoor-bold text-xl py-4 mb-4 w-full;
      max-width: 139px;

      &:hover {
        @apply bg-grey-button text-green-chat-lighter;
      }

      &.cancel-button {
        @apply bg-grey-button text-green-chat-lighter;
      }
    }
  }
}
</style>

<template>
    <div class="login-wrapper">
        <div class="login__header">
            <mda-logo />
        </div>
        <div class="login__body">
            <div class="login__container">
                <div class="title">
                    MDA National Live Chat
                </div>
                <div class="subtitle">
                    Log in to the adviser dashboard below.
                    <br>
                    All fields are required
                </div>
                <login-container />
            </div>
        </div>
    </div>
</template>

<script>
import MdaLogo from '../../assets/icons/Logo.svg';
import LoginContainer from './Login-Container';

export default {
    data() {
        return {
            bgImage: require('../../assets/images/login-bg.png'),
        }
    },
    components: {
        MdaLogo,
        LoginContainer
    }
}
</script>


<style scoped>
.login-wrapper {
    @apply flex h-full;
    flex-direction: column;

    .login {
        &__header {
            @apply flex pl-64 bg-black;
            align-items: center;
            height: 80px;  
        }

        &__body {
            @apply w-full h-full bg-repeat bg-cover;
            background-image: url('../../assets/images/login-bg.png');
        }

        &__container {
            @apply flex mx-auto mt-64 font-kohinoor-demi text-center text-white;
            flex-direction: column;
            max-width: 570px;

            .title {
                font-size: 42px;
            }

            .subtitle {
                @apply font-kohinoor-light mb-16;
                opacity: 0.90;
                font-size: 21px;
            }
        }
    }
}
</style>
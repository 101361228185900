<template>
  <div class="activity-header">
    <div class="activity-header__content">
      <div class="activity-header__section" v-bind:class="{active: currentChatFilter === 'All' }" @click="setActivityFilter('All')">
        All ({{ filterActivityNumbers('All') }})
      </div>
      <div class="activity-header__section" v-bind:class="{active: currentChatFilter === 'InProgress' }" @click="setActivityFilter('InProgress')">
        Chatting ({{ filterActivityNumbers('InProgress') }})
      </div>
      <div class="activity-header__section" v-bind:class="{active: currentChatFilter === 'Queue' }" @click="setActivityFilter('Queue')">
        Queued ({{ filterActivityNumbers('Queue') }})
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getActive } from '../mixins/getActive';

export default {
  mixins: [getActive],
  computed: {
    ...mapGetters(['activities', 'currentChatFilter']),
  },
  mounted() {
    this.setActivityFilter('All');
  },
  methods: {
    ...mapActions(['setActivityFilter']),

    setCurrentFilter(filter) {
      this.filterActivities(filter);
    },

    filterActivityNumbers(activity) {
      switch(activity) {
        case 'All':
          return this.activities.length;
        case 'InProgress':
          return this.activities.filter(a => a.Status === 'InProgress').length;
        case 'Queue':
          return this.activities.filter(a => a.Status === 'Queue').length;
        default:
          return 0;
      }
    },
  },
};
</script>

<style scoped>
.activity-header {
  @apply border-b-1 border-grey-divider;

  &__section {
    @apply pt-16 pb-8 font-kohinoor-demi text-mid-chat text-center duration-100 ml-17;
    width: 198px;

    &:hover, &.active {
      @apply font-kohinoor-med border-b-2 border-green-chat;
    }
  }

  &__content {
    @apply flex justify-start;
  }
}
</style>

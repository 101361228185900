<template>
  <div class='send-receive-component'>
      <div class="result">
        {{ computedTime }} {{ messageState }}
      </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  data() {
    return {
    }
  },
  props: ['sentState', 'time'],
  computed: {
    computedTime() {
      return moment.utc(this.time).local().format('LT');
    },
    messageState() {
      return `- ${this.sentState}`;
    }
  }
}
</script>
<style lang="scss" scoped>
.send-receive-component {
  .result {
    @apply font-kohinoor-demi text-grey-advisor text-small-chat;
  }
}
</style>
<template>
  <div>
    <div class="advisors" v-for="(user, index) in currentUsers" :key="index">
      <advisor v-if="!idToHide || (idToHide && user.UserId.toLowerCase() !== idToHide.toLowerCase())" :advisor="user" :channelId="channelId" :isTransferMode = "transferMode"/>
    </div>
  </div>
</template>

<script>
import Avatar from 'vue-avatar-component';
import Advisor from './Advisor'
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      items: [],
      url: require('../../assets/images/2_Layne_GettyImages-985138658@3x.jpg')
    };
  },
  props: ['idToHide', 'transferMode', 'channelId'],
  components: {
    Avatar, Advisor
  },
  computed: {
    ...mapGetters(['users', 'currentUsers'])
  },
  methods: {
    ...mapActions(['getAllAdvisors'])
  },

  created() {
    this.getAllAdvisors();
  }
};
</script>

<style scoped>
</style>

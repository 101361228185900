<template>
  <div
    class="media-wrapper"
    :class="isUserAdvisor(messageProp.UserId) ? 'media-wrapper--right' : ''"
  >
    <div class="media-wrapper__container">
      <div class="content-media" v-if="isMediaElement">
        <a
          target="_blank"
          rel="noopener noreferrer"
          :href="messageProp.MediaUrl"
        >
          <img :src="messageProp.MediaUrl" />
        </a>
        <a
          class="media-wrapper__message media-wrapper__message--advisor"
          target="_blank"
          rel="noopener noreferrer"
          :href="messageProp.MediaUrl"
          >{{ messageProp.MediaFileName }}
          </a>
      </div>
      <div class="content-document" v-else>
        <a
          class="media-wrapper__message media-wrapper__message--advisor"
          target="_blank"
          rel="noopener noreferrer"
          :href="messageProp.MediaUrl"
          >{{ messageProp.MediaFileName }}</a
        >
      </div>
      <div
        class="media-wrapper__send-state"
        v-if="isUserAdvisor(messageProp.UserId)"
      >
        <SendReceiveElement
          :sentState="
            messageProp.Read ? 'Read' : messageProp.Delivered ? 'Delivered' : ''
          "
          :time="messageProp.Updated"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SendReceiveElement from './SendReceiveElement';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isMediaElement: this.messageProp.Command === 'Media' ? true : false,
      url: require('../../assets/images/1_Justin_GettyImages-985138634@3x.jpg'),
    };
  },
  mounted() {},
  components: {
    //eslint-ignore-next-line
    SendReceiveElement,
  },
  props: ['messageProp', 'advisor'],
  methods: {
    isUserAdvisor(userId) {
      return this.advisor.UserId === userId ? true : false;
    },
  },
};
</script>

<style scoped>
.avatar {
  @apply font-kohinoor-light mt-7;
  font-size: 1.8rem !important;

  &--advisor {
    @apply text-green-chat;
  }
}

.media-wrapper {
  @apply flex;
  max-width: 600px;

  &--right {
    @apply ml-auto mr-0 pl-0 justify-end;
  }

  &__message {
    @apply border border-grey-divider rounded-bubble mt-3 p-7;

    &--advisor {
      @apply bg-blue-bubble text-white underline mb-3;
    }
  }
  &__container {
    @apply block mr-16;

    .content-media {
      @apply flex flex-col items-end mx-7 mt-7;
      & img {
        @apply rounded-bubble object-cover;
        max-width: 223px;
        max-height: 176px;
      }
    }
    .content-document {
      @apply flex flex-col items-end mx-7 mt-7;
    }
  }

  &__send-state {
    @apply ml-8 mt-2;
  }
}
</style>

import { mapActions } from 'vuex';

export const getActive = {
  methods: {
    ...mapActions(['initialiseActivitiesServices', 'initialiseChatServices', 'filterActivities', 'initialiseAdvisorsServices', 'cleanNotifications']),
    GetActiveAdvisor(activity) {
      const found = activity.ChannelUsers.filter(
        item => item.UserRoleName === 'Advisor' && item.UserStatus === 'Active'
      );

      const key = `${activity.id}advisor`;

      this.items[key] = found[0];
      return found[0];
    },
    GetActiveMember(activity) {
      const found = activity.ChannelUsers.filter(
        item => item.UserRoleName === 'Member' && item.UserStatus === 'Active'
      );

      const key = `${activity.id}member`;
      
      this.items[key] = found[0];
      return found[0];
    }
  }
};

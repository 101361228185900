import BeepSound from '!raw-loader!./../assets/sounds/beep.txt'
//global

var backgroundTimer = 0;
var tabFlashTimer = 0;
var timerInterval = 2000;
var notificationsShown = 0;
var initialTitle = "MDA-Chat";
var queueCount = 0;
var messagesCount = 0;
var transfersCount = 0;


function beep() {
  var snd = new Audio(BeepSound); 
  snd.currentTime = 0;
  snd.play();
}


const setTitle = () => {
  if ( queueCount > 0 || messagesCount > 0 || transfersCount > 0) { 
    document.title = "MDA-Chat " + queueCount + "Q | " + messagesCount + "M";
    beep();
    //setOriginICon('https://cdn4.iconfinder.com/data/icons/new-google-logo-2015/400/new-google-favicon-512.png');
      
  } else {
    //setOriginICon('https://www.pngfind.com/pngs/m/95-953441_big-image-computer-favicon-ico-hd-png-download.png');
    document.title = initialTitle;
    queueCount = 0;
    messagesCount = 0;
    transfersCount = 0;
      
  }
}
const getCountOfMemberMessages = (channelsArray) => {
  var count = 0;
  channelsArray.forEach((channel,index) => {
    var memberId = channel.ChannelUsers.filter(user => user.UserRoleName === 'Member')[0].UserId;
    channel.ChatMessages.forEach((message,messageIdx) => {
      if( message.UserId == memberId) {
        count +=1
      }
    });
  });
  return count;
}

const removeNotifications = (type) => {
  if (type === "queue") {
    queueCount = 0;
  } else if ( type === "message") {
    messagesCount = 0;
  } else if ( type === "transfer") {
    transfersCount = 0;
  }
}
const setNotification = (count, type) => {
  if (type === "queue") {
    queueCount = count;
  } else if ( type === "message") {
    messagesCount = count;
  } else if ( type === "transfer") {
    transfersCount = count;
  }
}

const hookUpNotifications = () => {

  //setOriginICon('https://www.pngfind.com/pngs/m/95-953441_big-image-computer-favicon-ico-hd-png-download.png');
  document.title = initialTitle;

  if ( typeof document.hidden === "undefined" ) {
    alert( "document.hidden is not supported - the Page Visibility API is missing" );
  } else {
    document.addEventListener( "visibilitychange", () => { updateVisibility( !document.hidden ) }, false );
  }
  
  if ( Notification.permission !== "granted" ) {
    Notification.requestPermission().then( 
        ( permission ) => { 
            if ( Notification.permission !== permission ) {
                Notification.permission = permission;
            }
            log( "Permission result: " + permission );
        }
    );
  }
  
  function updateVisibility( isVisible ) {
    log( "Visibility changed to " + (isVisible ? "visible":"hidden") );
    if ( isVisible ) {
        clearInterval( backgroundTimer );
        clearInterval( tabFlashTimer );
        //setOriginICon('https://www.pngfind.com/pngs/m/95-953441_big-image-computer-favicon-ico-hd-png-download.png');
        document.title = initialTitle;
    } else {
        tabFlashTimer = setInterval( () => { flashTab() }, timerInterval );
    }
  }
  
  function notify() {
    if (notificationsShown == 'transfer') {
      log( "Notification alert " + notificationsShown );
      new Notification( "There is a new transfer request", { persistent: true } );
    }
    else if (notificationsShown > 0) {
      log( "Notification alert " + notificationsShown );
      if (notificationsShown == 1) {
        new Notification( notificationsShown + " new message", { persistent: true } ); 
      } else {
        new Notification( notificationsShown + " new messages" , { persistent: true } ); 
      }
      
    }
    
  }
  function setOriginICon(iconUrl) {
    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = iconUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  function flashTab() {
    if ( initialTitle === null ) {
        initialTitle = document.title;
    }
  
    if ( queueCount > 0 || messagesCount > 0 || transfersCount > 0) { 
      document.title = "MDA-Chat " + queueCount + "Q | " + messagesCount + "M | " + transfersCount + "T";
      beep();
      //setOriginICon('https://cdn4.iconfinder.com/data/icons/new-google-logo-2015/400/new-google-favicon-512.png');
        
    } else {
      //setOriginICon('https://www.pngfind.com/pngs/m/95-953441_big-image-computer-favicon-ico-hd-png-download.png');
      document.title = initialTitle;
      queueCount = 0;
      messagesCount = 0;
      transfersCount = 0;
        
    }
  }
  
  function beep() {
    var snd = new Audio(BeepSound); 
    snd.currentTime = 0;
    snd.play();
  }
  
  function log( msg ) {
    //console.log(( (new Date()) + " - " + msg + "\r\n" ));
  }
}




export default {
  setTitle,
  getCountOfMemberMessages,
  hookUpNotifications,
  setNotification,
  removeNotifications
}

<template>
  <div class="logout">
    <h3 class="logout__text">Are you sure you want to log out?</h3>
    <div class="logout__button-container">
      <button class="logout__button" @click="logout()">
        yes
      </button>
      <button class="logout__button" @click.prevent="$emit('close')">
        no
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions([
      'clear',
      'initialiseChatServices',
      'initialiseActivityServices',
      'logOff',
      'clearChatServices',
      'clearAdvisorServices',
      'clearActivitiesServices'
    ]),
    logout() {
      this.logOff();
      this.clear();
      this.clearChatServices();
      this.clearAdvisorServices();
      this.clearActivitiesServices();
    },
  },
};
</script>

<style scoped>
.logout {
  @apply flex flex-col items-center align-middle justify-center h-full;

  &__button {
    @apply bg-grey-button text-green-chat-lighter uppercase font-kohinoor-bold text-small-chat py-4 w-full duration-100 mr-4 mt-9;
    max-width: 139px;

    &-container {
      @apply flex w-full align-middle justify-center;
    }

    &:hover {
      @apply bg-green-chat-lighter text-white;
    }
  }
}
</style>

import router from '../router/index';
import axios from 'axios';
import NotificationService from './NotificationService';
import LocalStorageService from './LocalStorageService';
import { store } from '../store/store';

import { constants } from '../constants';

const successHandler = (response) => {
  const url = response.config.url;

  if (response.status === 200) {
    NotificationService.clearErrorNotification(constants.REMOVE_NOTIFICATION);
    if (url.includes('TransferChannel')) {
      NotificationService.sendNotification(constants.TRANSFER_REQUEST_SENT);
    }

    if(url.includes('SendTranscript') || url.includes('SendMeTranscript')) {
      NotificationService.sendNotification(constants.EMAIL_TRANSCRIPT_SUCCESS);
    }
  }
  return response;
}

const errorHandler = (error) => {
  const url = error.config.url;

    if (!error.response || error.response.status === 404 || !navigator.onLine) {
      NotificationService.sendNotification(constants.SERVER_ERROR);
    } else {
      if(url.includes('RequestTransfer')) {
        NotificationService.sendNotification(constants.ERROR_TRANSFER_REQUEST);
      }
    
      if(url.includes('SendMessage')) {
        NotificationService.sendNotification(constants.ERROR_SENDING_MESSAGE);
      }
    
      if(url.includes('GetUpdates')) {
        NotificationService.sendNotification(constants.ERROR_RETRIEVING_CHATS);
      }
    
      if(url.includes('GetActivities')) {
        NotificationService.sendNotification(constants.ERROR_RETRIEVING_ACTIVITIES);
      }
  
      if(url.includes('SendTranscript')) {
        NotificationService.sendNotification(constants.EMAIL_TRANSCRIPT_ERROR);
      }
    
      if(url.includes('GetAdvisorByUsername') || url.includes('GetAllAdvisors')) {
        NotificationService.sendNotification(constants.ERROR_TRANSFER_REQUEST);
      }
    }

  if (error.response.status === 401) {
    
    const localStorageItem = LocalStorageService.getLocalStorageItem();
    let previousRequest = error.config;

    if (localStorageItem) {
      refreshToken(localStorageItem).then((response) => {
        const newLocalStorageItem = LocalStorageService.getLocalStorageItem();
        previousRequest.headers['Authorization'] = `Bearer ` + newLocalStorageItem.token;
        return axios.request(previousRequest);
      });
    }
  }

  return Promise.reject(error);
}

async function refreshToken(oldTokenDetails) {
  const rememberMe = LocalStorageService.getLocalStorageItemByKey("RememberMe");
  const queryString = `token=${oldTokenDetails.token}&currentUserName=${oldTokenDetails.UserName}&RememberMe=${rememberMe}`;
  
  // Refresh token
  axios.get(`/api/Login/RefreshToken?${queryString}`).then(({ data: { token } }) => {
    LocalStorageService.setLocalStorageDetails({ token, UserName: oldTokenDetails.UserName });
    return Promise.resolve(true);
  }).catch((error) => {
    const status = error.response ? error.response.status : null;
    if (status === 403) {
      store.dispatch('clear');
      store.dispatch('clearChatServices');
      store.dispatch('clearAdvisorServices');
      store.dispatch('clearActivitiesServices');
      localStorage.removeItem('mdaChatToken');
      router.push({ path: '/login' });
    }
    return Promise.reject(error);
  });
}

export default {
  errorHandler,
  successHandler
}
<template>
  <div class="dashboard">
    <dash-side-bar />
    <div class="dashboard__content">
      <BasicTopNotification />
      <advisors-header />
      <advisors-content :transferMode="false" />
    </div>
  </div>
</template>

<script>
import DashSideBar from '../DashSidebar';
import AdvisorsHeader from './AdvisorsHeader';
import AdvisorsContent from './AdvisorsContent';
import BasicTopNotification from '../Basic/BasicTopNotification';

export default {
  data() {
    return {
      userDescription: ''
    };
  },
  components: {
    DashSideBar,
    AdvisorsHeader,
    AdvisorsContent,
    BasicTopNotification
  }
};
</script>

<style scoped>
.dashboard {
  &__content {
    @apply flex flex-col w-full overflow-y-scroll;
  }
}
</style>

import mainAPI from '../mainAPI';

export const getAdvisorByUserName = {
    getAdvisorByName: params => {
      return mainAPI.get(`/api/advisors/GetAdvisorByUserName?userName=${params}`);
    }   
  };
  
 export  const uploadImage = {
    addFile: params => {
      return mainAPI.post('/api/advisors/UploadAdvisorProfileImage', params);
    }
  };

  export const editAdvisor = {
    editAdvisorProfile: params => {
      return mainAPI.post('/api/advisors/UpdateAdvisor', params)
    }
  }

import { mapActions } from 'vuex';


export const getActiveChat = {
  methods: {
    ...mapActions(['setActiveChat']),
   
    GetMemberChat(chat) { 
      const found = chat.ChannelUsers.filter(
        item => item.UserRoleName === 'Member' && item.UserStatus === 'Active'
      );
      const key = `${chat.Id}member`;
      this.items[key] = found[0];
      return found[0];
    }
  }
};

export const constants = {
    REQUEST_TRANSFER: 'RequestTransfer',
    REQUEST_ACCEPTED: 'RequestAccepted',
    REQUEST_REJECTED: 'RequestRejected',
    REQUEST_END: 'RequestEnd',
    WARNING_TO_CONTINUE: 'WarningToContinue',
    CTA_FOR_MEMBER_IN_QUEUE: 'CTAForMemberInQueue',
    CTA_FOR_MEMBER_ADVISOR_BUSY: 'CTAForMemberAdvisorBusy',
    CTA_FOR_MEMBER_MEMBER_IDLE: 'CTAForMemberMemberIdle',
    CTA_FOR_MEMBER_OUTSIDE_OFFICE_HOURS: 'CTAForMemberOutsideOfficeHours',

    TRANSFER_REQUEST_SENT: 'TRANSFER_REQUEST_SENT',
    EMAIL_TRANSCRIPT_SUCCESS: 'EMAIL_TRANSCRIPT_SUCCESS',
    REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
    EMAIL_TRANSCRIPT_ERROR: 'EMAIL_TRANSCRIPT_ERROR',
    ERROR_TRANSFER_REQUEST: 'ERROR_TRANSFER_REQUEST',
    ERROR_SENDING_MESSAGE: 'ERROR_SENDING_MESSAGE',
    ERROR_RETRIEVING_CHATS: 'ERROR_RETRIEVING_CHATS',
    ERROR_RETRIEVING_ACTIVITIES: 'ERROR_RETRIEVING_ACTIVITIES',
    ERROR_RETRIEVING_ADVISORS:  'ERROR_RETRIEVING_ADVISORS',
    SERVER_ERROR: 'SERVER_ERROR',
};


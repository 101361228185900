<template>
  <div class="context-wrapper">
    <hr />
    <div class="request-panel">
      {{ this.timeInUtc }}
      -
      {{ this.message }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { constants } from '../../constants';
import moment from 'moment';

export default {
  data() {
    return {
      message: '',
    };
  },
  mounted() {
    switch (this.messageProp.Command) {
      case constants.REQUEST_TRANSFER:
        this.message =
          'transfer to ' + this.messageProp.UserDisplayName + ' requested';
        break;
      case constants.REQUEST_ACCEPTED:
        this.message =
          'transfer accepted from ' + this.messageProp.UserDisplayName;
        break;
      case constants.REQUEST_REJECTED:
        this.message =
          'transfer rejected from ' + this.messageProp.UserDisplayName;
        break;
      case constants.REQUEST_END:
        if (this.chatProp.Status === 'Abandoned') {
          this.message = 'Chat has timed out';
        } else {
          this.message =
            'this conversation channel has been ended by ' +
            this.messageProp.UserDisplayName;
        }
        break;
      case constants.WARNING_TO_CONTINUE:
        this.message = 'warning to continue issued';
        break;
      case constants.CTA_FOR_MEMBER_IN_QUEUE:
        this.message = 'call to action - member in queue';
        break;
      case constants.CTA_FOR_MEMBER_ADVISOR_BUSY:
        this.message = 'call to action - member advisor busy';
        break;
      case constants.CTA_FOR_MEMBER_MEMBER_IDLE:
        this.message = 'call to action - member idle';
        break;
      case constants.CTA_FOR_MEMBER_OUTSIDE_OFFICE_HOURS:
        this.message = 'call to action - member outside of office hours';
        break;
      default:
        this.message = '';
    }
  },
  props: ['messageProp', 'chatProp'],
  computed: {
    timeInUtc() {
      return moment
        .utc(this.messageProp.Updated)
        .local()
        .format('LT');
    },
  },
};
</script>

<style scoped>
.context-wrapper {
  @apply mx-3 py-8;

  & hr {
    @apply text-grey-divider;
  }
  .request-panel {
    @apply font-kohinoor-demi mt-6 text-grey-advisor text-small-chat;
  }
}
</style>

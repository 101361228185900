<template>
  <div class="dashboard">
    <dash-side-bar />
    <div class="dashboard__content">
      <BasicTopNotification />
      <archive-search  v-if="!this.$store.state.Archives.initialSearchState"/>
      <archive-results v-else/>
    </div>
  </div>
</template>

<script>
import DashSideBar from '../DashSidebar';
import ArchiveSearch from './ArchiveSearch';
import ArchiveResults from './ArchiveResults';
import BasicTopNotification from '../Basic/BasicTopNotification';

export default {
  components: {
    DashSideBar,
    ArchiveSearch,
    ArchiveResults,
    BasicTopNotification
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.dashboard__content {
  @apply overflow-hidden;
}
</style>

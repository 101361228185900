import mainAPI from '../mainAPI';

const channels = {
    getChannel: params => {
        return mainAPI.post(`/api/chat/proxy/GetChannel`, params);
    },
    searchChannels: params => {
        return mainAPI.post(`/api/chat/proxy/SearchChannel`, params);
    },
    sendTranscript: params => {
        return mainAPI.post(`/api/chat/proxy/SendTranscript`, params)
    },
    sendMeTranscript: params => {
        return mainAPI.post(`/api/chat/proxy/SendMeTranscript`, params)
    }
    
};

export default {
    channels
}
  
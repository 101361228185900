<template>
  <div class="login-container">
    <div class="component">
      <login-component v-if="login"/>
      <forgot-password-component v-else/>
    </div>
  </div>
</template>

<script>

import LoginComponent from './Login-Component';
import ForgotPasswordComponent from './Forgot-Password-Component';
export default {
  data() {
    return {
      login: true
    }
  },
  components: {
    LoginComponent,
    ForgotPasswordComponent
  }
};
</script>

<style scoped>

.login-container {
  @apply w-full bg-white rounded-bubble py-16;
  color: #727272;
}
</style>
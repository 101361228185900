import axios from 'axios';
import ErrorHandlingService from '../services/ErrorHandlingService';
import LocalStorageService from '../services/LocalStorageService';;

const baseURL = '';


const instance = axios.create({
  baseURL,
  withCredentials: false
})

instance.interceptors.request.use((config) => {
  if (!config.url.includes('/Login/') || config.url.includes('/LogOffAdvisor')) {
    const localStorageItem = LocalStorageService.getLocalStorageItem();
    localStorageItem ? config.headers.Authorization = `Bearer ${localStorageItem.token}` : [];
  } 
  return config;
});

instance.interceptors.response.use(
  response => ErrorHandlingService.successHandler(response),
  error => ErrorHandlingService.errorHandler(error)
);

export default instance;

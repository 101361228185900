<template>
  <div class="chats-transfer__container">
    <div class="chats-transfer__title">Select Adviser to transfer Chat</div>
    <advisors-content :transferMode="true" :channelId="channelId" :idToHide="idToHide ? idToHide : ''" />
    <button class="chats-transfer__button" @click="closeModal">cancel</button>
  </div>
</template>

<script>
import AdvisorsContent from "../advisors/AdvisorsContent";
import { mapActions } from 'vuex';
export default {
  components: {
    AdvisorsContent
  },
  props: ["idToHide", "channelId"],
  methods: {
    ...mapActions(['resetModalRunningState']),

    closeModal() {
      this.resetModalRunningState();
      this.$emit('close')
    }
  }
};
</script>

<style scoped>
.chats-transfer {
  &__container {
    padding: 77px 86px 55px 86px;
  }

  &__title {
    @apply font-kohinoor-med text-title-chat;
  }

  &__button {
    @apply bg-grey-button text-green-chat-lighter uppercase font-kohinoor-bold text-small-chat py-4 mb-4 w-full;
    max-width: 139px;

    &:hover {
      @apply bg-green-chat-lighter text-white;
    }
  }

  &__speciality {
    @apply text-2xl;
  }
}
</style>

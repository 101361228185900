<template>
  <div class="archive-search__search-box">
    <div
      :class="[
        mini ? 'mini' : '',
        'archive-search__search-box archive-search__search-box--inline'
      ]"
    >
      <search-icon v-on:click="submitSearch" :class="[mini ? 'mini' : '']" />
      <input
        :class="[showError ? 'error' : '', mini ? 'mini' : '']"
        v-model="searchKeyword"
        type="text"
        placeholder="Type Reference Number / Member Number / Date"
        @keyup.enter="submitSearch"
      />
    </div>
    <div class="input-error-div" v-if="showError">{{ errorMessage }}</div>
  </div>
</template>
<script>
import SearchIcon from '../../assets/icons/Search.svg';
import { mapActions } from 'vuex';

export default {
  components: {
    SearchIcon
  },
  data() {
    return {
      showError: false,
      errorMessage: 'Field should not be empty'
    };
  },
  props: {
    mini: {
      type: Boolean
    }
  },
  computed: {
    searchKeyword: {
      get() {
        return this.$store.state.Archives.searchKeyword;
      },
      set(value) {
        this.$store.dispatch('setSearchKeyword', value);
      }
    }
  },
  methods: {
    ...mapActions(['triggerInitialState', 'getArchivedChats']),

    submitSearch() {
      if (this.$store.state.Archives.searchKeyword === '') {
        this.showError = true;
      } else {
        this.showError = false;
        this.getArchivedChats(this.$store.state.Archives.searchKeyword);
      }
    }
  }
};
</script>
<style scoped>
.archive-search {
  &__search-box {
    @apply flex flex-col items-center;

    &--inline {
      @apply flex-row w-full justify-center;
      max-width: 664px;

      &.mini {
        @apply my-10 pl-10 pr-12 min-w-full;

        & input {
          @apply pl-24 text-mid-chat;
          height: 44px;
        }

        & svg {
          @apply mt-0 !important;
          left: 43px;
          height: 22px;
          width: 22px;
        }
      }

      & svg {
        @apply mt-1 relative;
        left: 50px;

        &.mini {
          @apply ml-0 mt-4;
        }

        &:hover {
          @apply cursor-pointer;
        }
      }

      & input {
        @apply w-full flex bg-white pl-32 border border-solid border-grey-divider;
        height: 70px;
        border-radius: 15px;

        &::placeholder {
          @apply text-black opacity-50;
        }

        &:focus {
          @apply outline-none;
        }

        &.error {
          border-color: #ff0000;
        }
      }
    }
    .input-error-div {
      @apply mt-2 font-normal text-center;
      color: #ff0000;
    }
  }
}
</style>

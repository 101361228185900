import mainAPI from '../mainAPI';

const auth = {
    login: params => {
        return mainAPI.post(`/api/Login/ValidateAdvisor`, params)
    },
    refreshToken: params => {
        return mainAPI.get(`/api/Login/RefreshToken?${params}`);
    },

    logOut: params => {
        return mainAPI.post('/api/Login/LogOffAdvisor', params)
    }
}

export default {
    auth
}